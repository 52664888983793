import { Text, useBreakpointValue, VStack } from 'native-base';
import React from 'react';
import ShoppingCartItemHeader from './ShoppingCartItemHeader';
import ShoppingCartItemRow from './ShoppingCartItemRow';
import { useTranslation } from 'react-i18next';

const generateFullProductList = (originalList = []) => {
    let newList = [];

    originalList.forEach(item => {
        const { priceQuantity, pointQuantity } = item;

        if (priceQuantity) {
            const priceItem = { ...item };
            newList.push(priceItem);
        }

        if (pointQuantity) {
            const pointItem = { ...item, usePoints: true };
            newList.push(pointItem);
        }
    });

    return newList;
};

const ShoppingCartItemTable = ({
    data = [],
    editable = false,
    hideTitle = false,
    dropable = false,
}) => {
    const isNarrow = useBreakpointValue({
        base: true,
        md: false,
    });

    const keyExtractor = (i, index) => i.productId + '' + index;

    const { t } = useTranslation();

    const products = generateFullProductList(data);

    return (
        <VStack>
            {editable ? (
                <ShoppingCartItemHeader isNarrow={isNarrow} products={products} />
            ) : !hideTitle ? (
                <Text bold marginBottom={'5'}>
                    {t('Shopping cart')}
                </Text>
            ) : (
                <></>
            )}
            {products.map((item, index) => (
                <ShoppingCartItemRow
                    key={keyExtractor(item, index)}
                    product={item}
                    isNarrow={isNarrow}
                    editable={editable}
                    dropable={dropable}
                />
            ))}
        </VStack>
    );
};

export default ShoppingCartItemTable;
