import { HStack, Icon, Menu, Pressable, Text } from 'native-base';
import React from 'react';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { useNavigate } from 'react-router-dom';
import { extreactCategoryIdFromAction } from '../constrants/categories';
import { useShopInfo } from '../queries/shop';
import { pageRoutes } from '../Routes';
import { useGeneratePathFunc } from '../utils/routeUtils';

const CategoryButton = ({ category, triggerProps, onPress }) => {
    const { name, subCategories = [] } = category;
    return (
        <Pressable
            flexDirection={'row'}
            alignItems={'center'}
            onPress={onPress}
            {...triggerProps}>
            <Text fontSize={'sm'}>{name}</Text>
            {subCategories.length > 0 && (
                <Icon
                    as={FeatherIcon}
                    name={'chevron-down'}
                    color={'lightText'}
                />
            )}
        </Pressable>
    );
};

const DesktopCategoryMenu = () => {
    const { data: shopInfo } = useShopInfo();
    const { topNavigationBarLinks = [] } = shopInfo;

    const navigate = useNavigate();
    const generatePath = useGeneratePathFunc();

    const onCategoryPress = category => {
        const { actionTarget } = category;
        const categoryId = extreactCategoryIdFromAction(actionTarget);

        if (categoryId) {
            const url = generatePath(pageRoutes.category.path, {
                categoryId,
            });

            navigate(url);
        }
    };

    return (
        <HStack space={'5'} flex={1} flexWrap={'wrap'}>
            {topNavigationBarLinks.map((item, x) => {
                if (item.subCategories) {
                    return (
                        <Menu
                            key={`${x}`}
                            trigger={triggerProps => {
                                return (
                                    <CategoryButton
                                        category={item}
                                        triggerProps={triggerProps}
                                    />
                                );
                            }}>
                            {item.subCategories.map((subItem, y) => {
                                return (
                                    <Menu.Item
                                        key={`${x}_${y}`}
                                        onPress={() => {
                                            onCategoryPress(subItem);
                                        }}>
                                        {subItem.name}
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    );
                }
                return (
                    <CategoryButton
                        key={`${x}`}
                        category={item}
                        onPress={() => {
                            onCategoryPress(item);
                        }}
                    />
                );
            })}
        </HStack>
    );
};

export default DesktopCategoryMenu;
