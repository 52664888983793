import loadable from '@loadable/component';
import React from 'react';
import {
    createBrowserRouter,
    redirect,
    RouterProvider,
} from 'react-router-dom';
import ErrorPage from './ErrorPage';
import { makeQuery, queries } from './queries';
import Root from './Root';
import MyRecordScreen from './screens/MyRecordScreen';
import WithHeader from './WithHeader';
import ShoppingCartQueriesResetComp from './components/ShoppingCartQueriesResetComp';

const commonLoader =
    queryClient =>
    async ({ params }) => {
        const { lang, shopShortName } = params;
        const shopDomain = window.location.host;
        const query = makeQuery(queries.shopOgInfo({shopShortName, shopDomain}))

        const shopOgInfoQueryResult =
            queryClient.getQueryData(query.queryKey) ??
            (await queryClient.fetchQuery(query));

        if (!lang) {
            const { data = {} } = shopOgInfoQueryResult;
            const { defaultLocale } = data;
            const redirectUrl = shopShortName
                ? `/${defaultLocale}/shop/${shopShortName}`
                : `/${defaultLocale}`;
            return redirect(redirectUrl);
        }

        return shopOgInfoQueryResult;
    };

const CategoryScreen = loadable(() => import('./screens/CategoryScreen'));
const CheckoutDeliveryScreen = loadable(() =>
    import('./screens/CheckoutDeliveryScreen'),
);
const CheckoutDeliveryAdditionalScreen = loadable(() =>
    import('./screens/CheckoutDeliveryAdditionalScreen'),
);
const CheckoutFormScreen = loadable(() =>
    import('./screens/CheckoutFormScreen'),
);
const CheckoutPaymentSelectionScreen = loadable(() =>
    import('./screens/CheckoutPaymentSelectionScreen'),
);
const CheckoutScreen = loadable(() => import('./screens/CheckoutScreen'));
const CheckoutSummaryScreen = loadable(() =>
    import('./screens/CheckoutSummaryScreen'),
);
const PageNotFound = loadable(() => import('./screens/PageNotFound'));
const ProductListScreen = loadable(() => import('./screens/ProductListScreen'));
const ProductScreen = loadable(() => import('./screens/ProductScreen'));
const ShoppingCartScreen = loadable(() =>
    import('./screens/ShoppingCartScreen'),
);
const ShopScreen = loadable(() => import('./screens/ShopScreen'));
const LoginSuccess = loadable(() => import('./screens/LoginSuccess'));
const MemberScreen = loadable(() => import('./screens/MemberScreen'));
const MyRecordListScreen = loadable(() =>
    import('./screens/MyRecordListScreen'),
);
const PaymentSuccessScreen = loadable(() =>
    import('./screens/PaymentSuccessScreen'),
);
const PaymentFailScreen = loadable(() => import('./screens/PaymentFailScreen'));
const AboutUsScreen = loadable(() => import('./screens/AboutUsScreen'));
const AddressScreen = loadable(() => import('./screens/AddressScreen'));
const DeliveryPolicyScreen = loadable(() =>
    import('./screens/DeliveryPolicyScreen'),
);
const FaqScreen = loadable(() => import('./screens/FaqScreen'));
const MembershipScreen = loadable(() => import('./screens/MembershipScreen'));
const ReturnPolicyScreen = loadable(() =>
    import('./screens/ReturnPolicyScreen'),
);
const PrivacyPolicyScreen = loadable(() =>
    import('./screens/PrivacyPolicyScreen'),
);
const TncScreen = loadable(() => import('./screens/TncScreen'));

const PromotionDetailScreen = loadable(() => import('./screens/PromotionDetailScreen'));

export default ({ queryClient }) => {
    const allRoutes = [
        {
            path: '',
            element: (
                <ShoppingCartQueriesResetComp>
                    <WithHeader />
                </ShoppingCartQueriesResetComp>
            ),
            errorElement: <ErrorPage />,
            children: [
                {
                    path: '',
                    element: <ShopScreen />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'search/:keyword',
                    element: <CategoryScreen />,
                    errorElement: <ErrorPage />,
                    children: [
                        {
                            path: '',
                            element: <ProductListScreen />,
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'page/:page',
                            element: <ProductListScreen />,
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'page/:page/sort/:sortingMethod',
                            element: <ProductListScreen />,
                            errorElement: <ErrorPage />,
                        },
                    ],
                },
                {
                    path: 'category/:categoryId',
                    element: <CategoryScreen />,
                    errorElement: <ErrorPage />,
                    children: [
                        {
                            path: '',
                            element: <ProductListScreen />,
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'page/:page',
                            element: <ProductListScreen />,
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'page/:page/sort/:sortingMethod',
                            element: <ProductListScreen />,
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'product/:productId',
                            element: <ProductScreen />,
                            errorElement: <ErrorPage />,
                        },
                    ],
                },
                {
                    path: 'cart',
                    element: <ShoppingCartScreen />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'member',
                    element: <MemberScreen />,
                    errorElement: <ErrorPage />,
                    children: [
                        {
                            path: 'orders',
                            element: <MyRecordListScreen />,
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'orders/page/:page',
                            element: <MyRecordListScreen />,
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'order/:orderNo',
                            element: <MyRecordScreen />,
                            errorElement: <ErrorPage />,
                        },
                    ],
                },
                {
                    path: 'about-us',
                    element: <AboutUsScreen />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'address',
                    element: <AddressScreen />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'membership',
                    element: <MembershipScreen />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'faq',
                    element: <FaqScreen />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'delivery-policy',
                    element: <DeliveryPolicyScreen />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'return-policy',
                    element: <ReturnPolicyScreen />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'privacy-policy',
                    element: <PrivacyPolicyScreen />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'tnc',
                    element: <TncScreen />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'promotion/:promotionId',
                    element: <PromotionDetailScreen />,
                    errorElement: <ErrorPage />,
                }
            ],
        },
        {
            path: 'checkout',
            element: <CheckoutScreen />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: '',
                    element: <CheckoutFormScreen />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'shipping',
                    element: <CheckoutDeliveryScreen />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'address',
                    element: <CheckoutDeliveryAdditionalScreen />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'payment',
                    element: <CheckoutPaymentSelectionScreen />,
                    errorElement: <ErrorPage />,
                },
            ],
        },
        {
            path: 'loginSuccess',
            element: <LoginSuccess />,
            errorElement: <ErrorPage />,
        },
        {
            path: 'order/:orderNo/success',
            element: <CheckoutSummaryScreen />,
            errorElement: <ErrorPage />,
        },
        {
            path: 'payment/feedback/success',
            element: <PaymentSuccessScreen />,
            errorElement: <ErrorPage />,
        },
        {
            path: 'payment/feedback/:methodCode/success',
            element: <PaymentSuccessScreen />,
            errorElement: <ErrorPage />,
        },
        {
            path: 'payment/feedback/fail',
            element: <PaymentFailScreen />,
            errorElement: <ErrorPage />,
        },
    ];

    const router = createBrowserRouter(
        [
            {
                path: '',
                element: <Root />,
                errorElement: <ErrorPage showReloadButton={true} />,
                loader: commonLoader(queryClient),
                children: allRoutes,
            },
            {
                path: '/:lang',
                element: <Root />,
                errorElement: <ErrorPage showReloadButton={true} />,
                loader: commonLoader(queryClient),
                children: allRoutes,
            },
            {
                path: '/shop/:shopShortName',
                element: <Root />,
                errorElement: <ErrorPage showReloadButton={true} />,
                loader: commonLoader(queryClient),
                children: allRoutes,
            },
            {
                path: '/:lang/shop/:shopShortName',
                element: <Root />,
                errorElement: <ErrorPage showReloadButton={true} />,
                loader: commonLoader(queryClient),
                children: allRoutes,
            },
            {
                id: '404',
                path: '*',
                element: <PageNotFound />,
            },
        ],
        {
            basename: '/',
        },
    );

    return <RouterProvider router={router} />;
};
