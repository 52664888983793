import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import AspectRatio from '../platform-base-components/AspectRatio';
import { AnimatedImage } from './AnimatedComps';
import { useInView } from 'react-intersection-observer';
import { Center, View } from 'native-base';

export const SkeletonImage = ({
    ratio = 1,
    source,
    rounded = true,
    resizeMode,
    disabled,
    ...props
}) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const { ref, inView, entry } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    const fadeInOut = useSpring({
        opacity: isLoaded && inView ? 1 : 0,
    });

    const imageUrl = typeof source === 'object' ? source.uri : source;

    const [sizeProps, setSizeProps] = useState({});

    const containerRatio = typeof ratio === 'number' ? ratio : eval(ratio); 

    return (
        <AspectRatio ratio={ratio}>
            {resizeMode === 'contain' ? (
                <Center 
                    ref={ref}           
                    borderRadius={rounded ? 'xl' : 'none'}
                    overflow={'hidden'}
                    {...props}          
                >
                    <animated.img
                        src={imageUrl}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            borderRadius: rounded ? '12px' : 0,
                            ...fadeInOut,
                            ...sizeProps,
                        }}
                        onLoad={e => {
                            let width = e.target.naturalWidth;
                            let height = e.target.naturalHeight;
                            
                            const imageRatio = width / height;

                            if (imageRatio > containerRatio) {
                                setSizeProps({ width: '100%' });
                            } else if (imageRatio < containerRatio) {
                                setSizeProps({ height: '100%' });
                            } else {
                                setSizeProps({ height: '100%', width: '100%' });
                            }

                            setIsLoaded(true);
                        }}
                    />
                </Center>
            ) : (
                <AnimatedImage
                    ref={ref}
                    size={'full'}
                    source={source}
                    onLoad={() => {
                        setIsLoaded(true);
                    }}
                    alt={''}
                    {...props}
                    style={fadeInOut}
                    borderRadius={rounded ? 'xl' : 'none'}
                />
            )}
            {disabled && (
                <View
                    position={'absolute'}
                    top={0}
                    size={'full'}
                    backgroundColor={'black'}
                    opacity={0.4}
                    borderRadius={rounded ? 'xl' : 'none'}
                />
            )}
        </AspectRatio>
    );
};
