import React, { useEffect, useRef, useState } from 'react';
import { Image, View } from 'react-native';
import { Swiper, SwiperSlide } from 'swiper/react';
import _ from 'lodash';
import { Pagination } from 'swiper';
import { animated, useSpring } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { Center, Spinner } from 'native-base';
import ActionLink from './ActionLink';
import usePrevious from '../hooks/usePrevious';

const AnimatedImage = animated(Image)

let loadImageTask = 0;

const SelfSizeSwiperSlide = ({
  items,
  onShow,
}) => {
  const loadedImageList = useRef({});
  const [isLoading, setIsLoading] = useState();
  const prevIsloading = usePrevious(isLoading);

  const [windowSize, setWindowSize] = useState({});
  const { width: containerWidth, height: containerHeight } = windowSize;
  const windowWidth = containerWidth - 80;
  const windowHeight = containerHeight - 60;

  useEffect(() => {
    const loadedImages = _.map(loadedImageList.current, 'image');
    const imagesToLoad = _.filter(items, ({ image }) => !loadedImages.includes(image));

    if (imagesToLoad.length === 0) {
      setIsLoading(false);
      return;
    } else {
      setIsLoading(true);
      
      clearTimeout(loadImageTask);
      loadImageTask = setTimeout(() => {

        let finishCount = 0;
        const total = imagesToLoad.length;

        const finish = () => {
          finishCount = finishCount + 1;
          if (finishCount === total) {
            setIsLoading(false);
          }
        };

        _.forEach(imagesToLoad, ({ image, ...others }) => {
          Image.getSize(image, (width, height) => {
            loadedImageList.current[image] = {
              width, 
              height,
              aspectRatio: width / height,
              image,
              ...others,
            };
            finish();
          }, () => {
            finish();
          })
        });
      }, 500);
    }
  }, [items]);

  useEffect(() => {
    if (prevIsloading === true && isLoading === false) {
      onShow(_.values(loadedImageList.current));
    }
  }, [prevIsloading, isLoading, onShow]);

  return (
    <View
      style={{
          width: '100%',
          height: '100%',
      }}
      onLayout={(e) => {
        const newLayout = e.nativeEvent.layout;
        const { height: newHeight, width: newWidth } = newLayout;
        if (newHeight !== windowHeight || newWidth !== windowWidth) {
          setWindowSize(newLayout);
        }
      }}
    >
      {windowSize && isLoading === false ? 
      <Swiper
        className='popup'
        slidesPerView={'auto'}
        centeredSlides={true}
        pagination={true}
        modules={[Pagination]}
        spaceBetween={20}
        style={{
            width: '100%',
            height: '100%',
        }}
      >
        {_.map(items, ({ image, action, actionTarget }) => {
          const data = loadedImageList.current[image];
          if (data && data.aspectRatio) {
            const { width, height, aspectRatio } = data;
            let slideWidth = 0;
            let slideHeight = 0;

            if (windowWidth > windowHeight) {
              slideHeight = Math.min(windowHeight, height);
              slideWidth = slideHeight * aspectRatio;
            } else {
              slideWidth = Math.min(windowWidth, width);
              slideHeight = slideWidth / aspectRatio;
            }

            return (
              <SwiperSlide
                key={image}
                style={{
                  width: slideWidth,
                  height: windowHeight,
                }}
              >
                <ActionLink 
                  action={action} 
                  actionTarget={actionTarget}
                >
                  <FadeImage
                    source={{uri: image}}
                    style={{
                      width: slideWidth,
                      height: slideHeight,
                      marginTop: (windowHeight - slideHeight) / 2,
                      borderRadius: 20,
                    }}
                  />
                </ActionLink>
              </SwiperSlide>
            );
          }

          return null;
        })}
      </Swiper>
      : 
      <Center style={{ width: '100%', height: '100%' }}>
        <Spinner color={'white'}/>
      </Center>
      }
    </View>
  );
};

const FadeImage = ({ onShow, style, ...props }) => {
  const { ref, inView, entry } = useInView({
      threshold: 0,
      triggerOnce: true,
  });

  const fadeInOut = useSpring({
      opacity: inView ? 1 : 0,
  });

  useEffect(() => {
      if (inView && typeof onShow === 'function') {
          onShow();
      }
  }, [inView]);

  return (
      <AnimatedImage
          {...props}
          ref={ref}
          style={{
              ...style,
              ...fadeInOut,
          }}
      />
  );
};

export default React.memo(SelfSizeSwiperSlide);