import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLoaderData, useParams } from 'react-router-dom';
import { RNCenterLoading } from './components/RNCenterLoading';
import useAppLoginEffect from './hooks/useAppLoginEffect';
import { AppContextProvider } from './providers/AppProvider';
import { ShopOGInfoProvider } from './providers/ShopOGInfoContext';
import { RootHead } from './SEO/RootHead';
import { useLogout, useShopInfoQuery } from './queries/shop';
import AppThemeProvider from './AppThemeProvider';
import ShoppingCartSessionLoader from './ShoppingCartSessionLoader';

const Wrapper = ({ shopId, lang }) => {
    const { isAppLoading, appLoginError } = useAppLoginEffect({ lang, shopId });
    const { mutate: logout, isLoading: isLoggingOut } = useLogout(false);

    const { isLoading: isShopInfoLoading, error, isError } = useShopInfoQuery({
        shopId, lang,
        enabled: !isAppLoading,
    });

    const messages = useMemo(() => {
        if (error?.response?.data?.errorMsgs) {
          return error.response.data.errorMsgs.map(error => error.message).concat('\n');
        }
    
        return '';
    }, [error]);

    useEffect(() => {
        if (error?.response?.status === 403) {
            logout({ shopId, lang });
        }
    }, [error]);

    if (isShopInfoLoading || isError || isAppLoading || appLoginError || isLoggingOut) {
        return (
            <RNCenterLoading 
                error={messages} 
                showReloadButton={appLoginError || isError} 
            />
        );
    }

    return (
        <AppThemeProvider>
            <Outlet />
        </AppThemeProvider>
    );
};

export default () => {
    const { data } = useLoaderData();
    const { shopId } = data;
    const { lang, shopShortName } = useParams();
    const shopDomain = window.location.host;
    const isUsingShopDomain = shopShortName ? false : true;
    const { i18n } = useTranslation();
    const loaded = useRef(false);

    useEffect(() => {
        i18n.changeLanguage(lang);
        loaded.current = true;
    }, [lang]);

    return (
        <AppContextProvider 
            shopShortName={shopShortName}
            shopId={shopId}
            shopDomain={shopDomain}
            lang={lang}
            isUsingShopDomain={isUsingShopDomain}
        >
            {loaded.current && (
                <ShoppingCartSessionLoader>
                    <ShopOGInfoProvider>
                        <RootHead />
                        <Wrapper
                            lang={lang}
                            shopId={shopId}
                        />
                    </ShopOGInfoProvider>
                </ShoppingCartSessionLoader>
            )}
        </AppContextProvider>
    );
};
