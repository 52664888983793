import { actions, extractFromAction } from './actions';

export const SpecialCategories = {
    all: {
        id: 'all',
        title: 'All',
    },
    memberOnly: {
        id: 'memberOnly',
        title: 'Member only',
    },
    preorder: {
        id: 'preorder',
        title: 'Preorder',
    },
    spotGoods: {
        id: 'spotGoods',
        title: 'Spot goods',
    },
    specials: {
        id: 'specials',
        title: 'Specials',
    },
    favorite: {
        id: 'favorite',
        title: 'My favorite',
    },
};

export const extreactCategoryIdFromAction = actionTarget => {
    const { scheme, id } = extractFromAction(actionTarget);

    if (scheme === actions.category.scheme) {
        return id;
    }

    return null;
};
