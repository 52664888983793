import { generatePath } from '../utils/routeUtils';
import { apiRoutes } from '../Routes';
import shopApiClient from '../api/shopApiClient';

export const getShopOGInfo = async (shopShortName, shopDomain) => {
    const url = shopShortName
        ? apiRoutes.shopOgInfo + '/' + shopShortName
        : apiRoutes.shopOgInfo;

    const res = await shopApiClient.get({
        url,
        params: {
            shopDomain,
        },
    });

    return res.data;
};

export const getMemberLoginLink = ({ lang, shopId, redirectUrl }) => {
    const url = generatePath(apiRoutes.memberLogin, {
        lang,
        shopId,
    });

    return shopApiClient.get({
        url,
        params: {
            redirectUrl,
        },
    });
};

export const addShopProductToCart = ({
    lang,
    shopId,
    productId,
    stockId,
    priceQuantity = 0,
    pointQuantity = 0,
}) => {
    const url = generatePath(apiRoutes.shoppingCartCounting, {
        lang,
        shopId,
    });

    return shopApiClient.post({
        url,
        data: {
            productId,
            stockId,
            priceQuantity,
            pointQuantity,
        },
        // headers: {
        //     'x-my-cart': encodeURI(`["${productId}|${stockId}|${priceQuantity}|${pointQuantity}"]`),
        // }
    });
};

export const postFavorite = ({ lang, shopId, productId }) => {
    const url = generatePath(apiRoutes.favoriteProduct, {
        lang,
        shopId,
        productId,
    });

    return shopApiClient.post({
        url,
    });
};

export const deleteFavorite = ({ lang, shopId, productId }) => {
    const url = generatePath(apiRoutes.favoriteProduct, {
        lang,
        shopId,
        productId,
    });

    return shopApiClient.delete({
        url,
    });
};

export const updateCartItem = ({
    lang,
    shopId,
    productId,
    stockId,
    priceQuantity,
    pointQuantity,
}) => {
    const url = generatePath(apiRoutes.shoppingCartDetail, {
        lang,
        shopId,
    });

    return shopApiClient.put({
        url,
        data: {
            productId,
            stockId,
            priceQuantity,
            pointQuantity,
        },
    });
};

export const deleteCartItem = ({ lang, shopId, productId, stockId }) => {
    const url = generatePath(apiRoutes.shoppingCartDetail, {
        lang,
        shopId,
    });

    return shopApiClient.delete({
        url,
        params: {
            productId,
            stockId,
        },
    });
};

export const updateCartRemark = ({ lang, shopId, remark }) => {
    const url = generatePath(apiRoutes.shoppingCartDetail, {
        lang,
        shopId,
    });

    return shopApiClient.patch({
        url,
        data: {
            remark,
        },
    });
};

export const logout = ({ lang, shopId }) => {
    const url = generatePath(apiRoutes.memberLogin, {
        lang,
        shopId,
    });

    return shopApiClient.delete({
        url,
    });
};

export const login = ({ lang, shopId, memberId }) => {
    const url = generatePath(apiRoutes.memberLogin, {
        lang,
        shopId,
    });

    return shopApiClient.post({
        url,
        data: {
            memberId,
        },
    });
};

export const createOrder = ({ lang, shopId, pendingOrderNo }) => {
    const url = generatePath(apiRoutes.orderDetail, {
        lang,
        shopId,
        orderNo: pendingOrderNo,
    });

    return shopApiClient.post({
        url,
        data: {},
    });
};

export const deletePendingOrder = ({ lang, shopId, pendingOrderNo }) => {
    const url = generatePath(apiRoutes.pendingOrderDetail, {
        lang,
        shopId,
        pendingOrderNo,
    });

    return shopApiClient.delete({
        url,
    });
};

export const getPageContent = ({ lang, shopId, page }) => {
    const url = generatePath(apiRoutes.shopPageContent, {
        lang,
        shopId,
        page,
    });

    return shopApiClient.get({
        url,
    });
};

export const postPaymentAction = ({ lang, shopId, pendingOrderNo, action, cardNetwork, tokenizationData, validationURL }) => {
    const url = generatePath(apiRoutes.payment, {
        lang,
        shopId,
        pendingOrderNo,
    });

    return shopApiClient.post({
        url,
        data: {
            action,
            data: {
                cardNetwork, // for google pay
                tokenizationData,
                validationURL, // for apple pay
            },
        },
    });
}