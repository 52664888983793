import { Center, Spinner, View } from 'native-base';
import React from 'react';

const Loading = () => {
    return (
        <Center
            w={'full'}
            height={'full'}
            position={'absolute'}
            top={0}
            bg={'black:alpha.10'}>
            <Spinner color={'muted.700'} />
        </Center>
    );
};

export default Loading;
