import { extendTheme, HStack, Icon, Input, NativeBaseProvider, VStack } from 'native-base';
import React from 'react';
import { useState } from 'react';
import Feather from 'react-native-vector-icons/dist/Feather';
import { useTranslation } from 'react-i18next';


const SearchBox = ({ onEnterPress = () => {}, quickSearch = false, header=true, bg=null}) => {
    const [text, setText] = useState('');
    const { t } = useTranslation();

    return (
        <Input
            w={ header ? {
                base: 'full',
                md: '40',
                lg: '56',
                } : 'full'
            }
            value={text}
            variant={'unstyled'}
            rounded={'full'}
            backgroundColor={!header ? bg ?? 'transparent' : 'muted.100'}
            rightElement={
                <Icon
                    as={Feather}
                    name={'search'}
                    size={21}
                    color={'lightText'}
                    mr={'2'}
                />
            }
            onChangeText={setText}
            onKeyPress={e => {
                if (e.nativeEvent.key === 'Enter' && text && quickSearch === false) {
                    onEnterPress(text);
                }
            }}
            onChange={(e)=>{
                    if(quickSearch === true){
                        setText(e.target.value);
                        onEnterPress(e.target.value);
                    }
                }   
            }
            color={'lightText'}
            placeholder={t('Search')}
            placeholderTextColor={'lightText'}
            fontSize={'14px'}

            borderWidth={header ? null : 1}
            borderColor={header ? null : 'lightText'}
        />
    );
};

export default SearchBox;
