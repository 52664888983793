import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeQueryFn, queries } from '../queries';
import { usePureLogin, usePureLogout } from '../queries/shop';
import { setIsAppAction, setOsAction } from '../store/actions/MemberAction';

const SEND_MESSAGE_DEBUG = false;

const isReactNativeWebView = !!window.ReactNativeWebView;
let isRunning = false;

const sleep = async (ms) => new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, ms);
})

const useAppLoginEffect = ({ lang, shopId }) => {
    const dispatch = useDispatch();

    const { mutateAsync: memberLoginSliently } = usePureLogin();
    const { mutateAsync: logoutSliently } = usePureLogout();

    const [appLoginError, setAppLoginError] = useState(false);
    const [isAppLoading, setIsAppLoading] = useState(isReactNativeWebView);
    const queryClient = useQueryClient();
    const isReadySent = useRef(false);

    useEffect(() => {
      if (!isReactNativeWebView) return;
      
      const sendMessage = (message) => {
        if (SEND_MESSAGE_DEBUG) {
          window.ReactNativeWebView.postMessage(JSON.stringify({
            message,
          }));
        }
      };

      window.appLogin = async(input) => {
        if (isRunning) return;
        isRunning = true;

        setIsAppLoading(true);
        const appData = JSON.parse(input);
    
        const { 
          memberid: appMemberId,
          sid: appShopId,
          sn: appShopName,
          os,
        } = appData;

        let memberId = undefined;
        let anyError = undefined;
        let reloadData = false;

        try {
          const { queryKey, queryMeta } = queries.cartDetail({ lang, shopId });
          const queryFn = makeQueryFn(queryMeta);
          const cartDetail = await queryClient.fetchQuery({ queryKey, queryFn });
          memberId = cartDetail?.data?.memberInfo?.id;
          sendMessage('current member id: ' + memberId);
        } catch (error) {
          anyError = error;
          sendMessage('get cart detail failed');
        }

        await sleep(100);

        if (memberId && (!appMemberId || memberId !== appMemberId)) {
          try {
            await logoutSliently({ lang, shopId });
            reloadData = true;
            sendMessage('logout successful');
          } catch (error) {
            anyError = error;
            sendMessage('logout failed');
          }
        }

        await sleep(100);

        if (appMemberId && memberId !== appMemberId) {
          try {
            await memberLoginSliently({ lang, shopId, memberId: appMemberId });
            reloadData = true;
            sendMessage('login successful');
          } catch (error) {
            anyError = error;
            sendMessage('login failed');
          }
        }

        await sleep(100);

        if (anyError) {
          // show the reload button later if error occurred
          setTimeout(() => setAppLoginError(true), 5000);
        } else {
          setIsAppLoading(false);
        }

        if (reloadData) {
          queryClient.invalidateQueries([shopId, lang]);
        }

        dispatch(setOsAction(os));
        isRunning = false;
      };

      if (!isReadySent.current) {
        isReadySent.current = true;
        window.ReactNativeWebView.postMessage(JSON.stringify({
          state: 'ready',
        }));
      }
    }, [lang, shopId]);

    useEffect(() => {
      if (!isReactNativeWebView) return;

      dispatch(setIsAppAction(true));

      const disableMenuEvent = (e) => e.preventDefault();
      document.addEventListener('contextmenu', disableMenuEvent);

      return () => {
        document.removeEventListener('contextmenu', disableMenuEvent)
      }
    }, []);

    return {
      isAppLoading, appLoginError
    }
};

export default useAppLoginEffect;