import * as Order from '../constants/Order';
import * as ShoppingCart from '../constants/ShoppingCart';

const initialState = {
    list: [],
};

const OrderReducer = (state = initialState, action) => {
    if (action.type === Order.ADD_ORDER) {
        const { shoppingCart, member } = action.payload;

        const {
            form,
            total,
            paymentOption,
            skus,
            products,
            quantities,
            subTotal,
        } = shoppingCart;

        const { name: customerName, points } = member;

        const id = Date.now();
        let list = [...state.list];

        list.push({
            id,
            number: 'o' + id,
            earnedPoints: total / 100,
            requiredUpgradePoints: points + total / 100,
            date: new Date().toDateString(),
            status: Order.Status.paid,
            paymentMethod: paymentOption.type,
            transactionId: '' + Date.now(),
            customer: {
                name: customerName,
            },
            recipient: {
                name: form[ShoppingCart.FormFieldName.recipient.name],
                phone: form[ShoppingCart.FormFieldName.recipient.phone],
                email: form[ShoppingCart.FormFieldName.recipient.email],
                address: [
                    form[ShoppingCart.FormFieldName.country],
                    form[ShoppingCart.FormFieldName.addressLine1],
                    form[ShoppingCart.FormFieldName.addressLine2],
                    form[ShoppingCart.FormFieldName.area],
                ],
            },
            items: {
                skus,
                products,
                quantities,
                subTotal,
            },
            total,
        });

        return {
            list,
        };
    }

    return state;
};

export default OrderReducer;