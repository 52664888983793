import { compile, match, pathToRegexp } from 'path-to-regexp';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../providers/AppProvider';
import { apiRoutes, pageRoutes } from '../Routes';

export const matchPath = (path, url) => {
    const matchFn = match(path, { decode: decodeURIComponent });
    return matchFn(url);
};

export const generatePath = (path, param) => {
    let output = null;

    const toPath = compile(path, { encode: encodeURIComponent });

    try {
        output = toPath(param);
    } catch (e) {
        
    }

    return output;
};

export const usePathMaker = {
    shop: () => useGeneratePath(pageRoutes.shop.path),
    category: categoryId =>
        useGeneratePath(pageRoutes.category.path, { categoryId }),
    product: (categoryId, productId) =>
        useGeneratePath(pageRoutes.product.path, { categoryId, productId }),
    search: keyword => useGeneratePath(pageRoutes.search.path, { keyword }),
    cart: () => useGeneratePath(pageRoutes.cart.path),
    checkout: () => useGeneratePath(pageRoutes.checkout.path),
    shipping: () => useGeneratePath(pageRoutes.shipping.path),
    address: () => useGeneratePath(pageRoutes.address.path),
    payment: () => useGeneratePath(pageRoutes.payment.path),
    orderSuccess: orderNo =>
        useGeneratePath(pageRoutes.orderSuccess.path, { orderNo }),
    orders: () => useGeneratePath(pageRoutes.orders.path),
    orderDetails: () => useGeneratePath(pageRoutes.orderDetails.path),
};

export const generatePathOrNull = (path, params) => {
    try {
        return generatePath(path, params);
    } catch (e) {
        // console.log(e);
    }

    return null;
};

export const useGeneratePathFunc = () => {
    const { lang, shopShortName } = useParams();
    const { isUsingShopDomain } = useAppContext();

    return (path = '', params = {}) => {
        return generatePathOrNull(
            isUsingShopDomain ? path.replace('/shop/:shopShortName', '') : path,
            {
                lang,
                shopShortName: isUsingShopDomain ? null : shopShortName,
                ...params,
            },
        );
    };
};

const useGeneratePath = (path = '', params = {}) => {
    const generatePathFunc = useGeneratePathFunc();
    return generatePathFunc(path, params);
};

const shopShortNameRegexp = pathToRegexp(pageRoutes.shop.path, [], {
    end: false,
});

export const getShopShortName = (pathname) => {
    const result = shopShortNameRegexp.exec(pathname);
    const shopShopName = result && result[2];

    return shopShopName
};

const shopOgInfoPathRegexp = pathToRegexp(apiRoutes.shopOgInfo);

export const isShopOgInfoPath = (url) => {
    return shopOgInfoPathRegexp.test(url);
}

const shopInfoPathRegexp = pathToRegexp(apiRoutes.shopInfo);

export const isShopInfoPath = (url) => {
    return shopInfoPathRegexp.test(url);
}

export const getShopId = (pathname) => {
    const result = shopInfoPathRegexp.exec(pathname);
    const shopId = result && result[2];

    return shopId;
};

const shopPageRegexp = pathToRegexp(pageRoutes.shop.path);

export const isShopPage = (url) => {
    return shopPageRegexp.test(url);
}