import * as PRODUCT from '../constants/Product';

const initialState = {
    selectedStock: {},
};

const ProductReducer = (state = initialState, action) => {
    if (action.type === PRODUCT.SAVE_SELECTED_STOCKS) {
        const { id, stocks } = action.payload;
        return {
            ...state,
            selectedStock: {
                ...state.selectedStock,
                [id]: stocks,
            },
        };
    } else if (action.type === PRODUCT.RESET_SELECTED_STOCKS) {
        return {
            ...state,
            selectedStock: {},
        };
    }
    
    return state;
};

export default ProductReducer;