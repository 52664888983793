import {
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { useToast } from 'native-base';
import config from '../environments';
import { requests } from './requests';

const fetcher = ({ queryKey, pageParam, pageLength }) => {
    const [url, params] = queryKey;

    return requests
        .get({
            url: config.apiUrl + url,
            params: {
                itemStart: pageParam,
                itemLength: pageLength,
                ...params,
            },
        })
        .then(res => res.data);
};

export const useFetch = ({ url, params, config }) => {
    const context = useQuery(
        [url, params],
        ({ queryKey }) => fetcher({ queryKey }),
        {
            enabled: !!url,
            ...config,
        },
    );

    return context;
};

export const useLoadMore = ({ url, params, pageLength = 4 }) => {
    const context = useInfiniteQuery(
        [url, params],
        ({ queryKey, pageParam = 0 }) =>
            fetcher({ queryKey, pageParam, pageLength }),
        {
            getNextPageParam: (lastPage, allPages) => {
                const nextItemStart = allPages.length * pageLength;
                return nextItemStart > lastPage.data.itemTotal
                    ? false
                    : nextItemStart;
            },
            enabled: !!url,
        },
    );

    return context;
};

export const useMutationWithHandling = (api, options = {}) => {
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation(api, {
        onSuccess: res => {
            if (res?.config?.url) {
                const queryKey = res.config.url.replace(config.apiUrl, '');
                queryClient.invalidateQueries([queryKey]);
            }

            if (typeof options.onSuccess === 'function') {
                options.onSuccess(res);
            }

            if (Array.isArray(options.invalidQueryKeys)) {
                options.invalidQueryKeys.forEach(queryKey => {
                    queryClient.invalidateQueries([queryKey]);
                });
            }
        },
        onError: error => {
            if (error?.response?.data?.errorMsgs) {
                const messages = error.response.data.errorMsgs
                    .map(error => error.message)
                    .concat('\n');
                toast.show({
                    description: messages,
                });
            }

            if (typeof options.onError === 'function') {
                options.onError(error);
            }
        },
    });
};

export const trimCountingFromDetail = (data) => {
    const { inventoryAmt, itemAmt } = data.data;
    let trimData = {...data};
    trimData.data = {inventoryAmt, itemAmt};
    return trimData;
}