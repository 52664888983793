import { Platform } from 'react-native';
// import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

export function translate(props) {
    var title = '';
    const { t } = useTranslation();

    if (Array.isArray(props)) {
        title = t(props.join('.'));
    } else if (typeof props === 'string') {
        title = t(props);
    }

    return title;
}

export const useTranslateFunc = () => {
    const { t } = useTranslation();

    return props => {
        var title = '';

        if (Array.isArray(props)) {
            title = t(props.join('.'));
        } else if (typeof props === 'string') {
            title = t(props);
        }

        return title;
    };
};

export const copyAndAssignValues = (form, data) => {
    const { name, value } = data;
    const newForm = { ...form };

    if (Array.isArray(value)) {
        newForm[name] = [...value];
    } else if (value === null) {
        delete newForm[name];
    } else if (typeof value === 'object') {
        newForm[name] = { ...value };
    } else {
        newForm[name] = value;
    }

    return newForm;
};
