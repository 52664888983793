import { HStack, Icon, Pressable, Text, VStack } from 'native-base';
import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';
import Feather from 'react-native-vector-icons/dist/Feather';
import ActionLink from './ActionLink';

const MenuButton = ({
    title,
    link,
    children = [],
    isOpen,
    onPress,
    action = '_self',
    actionTarget,
    isSmall = false,
}) => {
    if (actionTarget) {
        return (
            <ActionLink action={action} actionTarget={actionTarget}>
                <Text fontSize={isSmall ? 'sm' : 'md'}>{title}</Text>
            </ActionLink>
        );
    }

    if (link) {
        return (
            <Link to={link} reloadDocument={false}>
                <Text fontSize={isSmall ? 'sm' : 'md'}>{title}</Text>
            </Link>
        );
    }

    return (
        <Pressable onPress={onPress}>
            <HStack alignItems={'baseline'} justifyContent={'space-between'}>
                <Text fontSize={isSmall ? 'sm' : 'md'}>{title}</Text>
                {children.length > 0 && (
                    <Icon
                        as={Feather}
                        name={isOpen ? 'chevron-up' : 'chevron-down'}
                    />
                )}
            </HStack>
        </Pressable>
    );
};

const AnimatedVStack = animated(VStack);

const MenuItem = ({
    title = '',
    link = '',
    children,
    action = '_self',
    actionTarget,
    containerStyle = {},
    isSmall = false,
}) => {
    const [open, setOpen] = useState(false);
    const [ref, { height: childrenHeight }] = useMeasure();

    const { height } = useSpring({
        form: { height: 0 },
        to: { height: open ? childrenHeight : 0 },
    });

    return (
        <VStack marginY={'1'} style={containerStyle}>
            <MenuButton
                title={title}
                link={link}
                children={children}
                isOpen={open}
                onPress={() => {
                    setOpen(!open);
                }}
                action={action}
                actionTarget={actionTarget}
                isSmall={isSmall}
            />
            {children && (
                <AnimatedVStack
                    marginLeft={'5'}
                    paddingLeft={'5'}
                    borderLeftWidth={'1'}
                    borderColor={'lightText'}
                    style={{
                        overflow: 'hidden',
                        height: childrenHeight > 0 ? height : 'auto',
                    }}>
                    <VStack ref={ref}>{children}</VStack>
                </AnimatedVStack>
            )}
        </VStack>
    );
};

export default MenuItem;
