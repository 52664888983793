import axios from 'axios';
import { useEffect, useState } from 'react';
import config from '../environments';
import { getShopId, getShopShortName, isShopInfoPath, isShopOgInfoPath } from '../utils/routeUtils';
import { loginKey } from '../hooks/useIsLoggedIn';

const listOfMyHeader = ['x-my-cart', 'x-my-cart-remark', 'x-my-token'];

const instance = axios.create({
    baseURL: config.apiUrl,
    withCredentials: true,
});

const getPrefix = (shopShortName) => {
    return `${shopShortName ?? window.location.host}.headers`;
}

export const ShopApiInterceptor = ({
    children,
}) => {
    const [isSet, setIsSet] = useState(false);

    useEffect(() => {
        const requestInterceptor = instance.interceptors.request.use((config) => {
            const shopShortName = getShopShortName(window.location.pathname);

            if (isShopOgInfoPath(config.url) || shopShortName) {
                return config;
            }

            const prefix = getPrefix(shopShortName);

            listOfMyHeader.forEach(header => {
                const value = localStorage.getItem(`${prefix}.${header}`);
                if (value) {
                    config.headers[header] = value;
                } else {
                    config.headers[header] = '';
                }
            });

            return config;
        });

        const responseInterceptor = instance.interceptors.response.use((response) => {
            const shopShortName = getShopShortName(window.location.pathname);

            // fix: use token to determine login status
            if (isShopInfoPath(response.config.url)) {
                const shopId = getShopId(response.config.url);
                if (shopId) {
                    const key = loginKey(shopId);
                    if (response.headers['x-my-token'] && response.headers['x-my-token'].length > 0) {
                        localStorage.setItem(key, true);
                    } else {
                        localStorage.setItem(key, false);
                    }
                }
            }

            if (isShopOgInfoPath(response.config.url) || shopShortName) {
                return response;
            }

            const prefix = getPrefix(shopShortName);

            listOfMyHeader.forEach(header => {
                const value = response.headers[header];
                if (value) {
                    localStorage.setItem(`${prefix}.${header}`, value);
                } else {
                    localStorage.setItem(`${prefix}.${header}`, '');
                }
            });
            
            return response;
        });

        setIsSet(true);

        return () => {
            instance.interceptors.request.eject(requestInterceptor);
            instance.interceptors.response.eject(responseInterceptor);
        }
    }, []);

    return (
        <>
            {isSet && children}
        </>
    );
}

export default {
    get: ({ url, params }) => 
        instance.get(url, {
            params,
        }),
    post: ({ url, data, headers }) =>
        instance.post(url, data, {
            headers,
        }),
    put: ({ url, data }) => 
        instance.put(url, data, {}),
    delete: ({ url, params }) =>
        instance.delete(url, {
            params,
        }),
    patch: ({ url, data }) => 
        instance.patch(url, data, {}),
};