import {
    HStack,
    Icon,
    IconButton,
    Image,
    Text,
    View,
    ZStack,
    Menu
} from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import Ionicons from 'react-native-vector-icons/dist/Ionicons';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useMeasure from 'react-use-measure';
import { useTranslation } from 'react-i18next';
import { useIsLoggedIn } from '../hooks/useIsLoggedIn';
import { useAppContext } from '../providers/AppProvider';
import { useCartDetail } from '../queries/cart';
import { useLogin, useLogout, useShopInfo } from '../queries/shop';
import { pageRoutes } from '../Routes';
import { useIsMobile } from '../utils/hooks';
import { useGeneratePathFunc, usePathMaker } from '../utils/routeUtils';
import useCurrentPage from '../utils/useCurrentPage';
import { AdComponent } from './AdComponent';
import DesktopCategoryMenu from './DesktopCategoryMenu';
import LanguageMenuButton from './LanguageMenuButton';
import MenuModal from './MenuModal';
import SearchBox from './SearchBox';
import ShoppingCartModal from './ShoppingCartModal';
import { actions } from '../constrants/actions';

const NavigationHeader = ({ navigation, back, onHeightChanged = () => { } }) => {
    const { lang, shopId } = useAppContext();

    const [showShoppingCart, setShowShoppingCart] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const isMobile = useIsMobile();

    const favoritePath = usePathMaker.category('favorite');
    const homeLink = usePathMaker.shop();
    const shoppingCartLink = usePathMaker.cart();
    const ordersPath = usePathMaker.orders();

    const { data: cart } = useCartDetail(); // reduce api call
    const { data: shopInfo } = useShopInfo();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const isLoggedIn = useIsLoggedIn();

    const onMenuPress = () => {
        setShowMenu(true);
    };

    const onFavoritePress = () => {
        navigate(favoritePath);
    };

    const onBackPress = () => {
        navigate(-1);
    };

    const onShoppingCartPress = () => {
        setShowShoppingCart(!showShoppingCart);
    };

    const onShoppingCartClosePress = () => {
        setShowShoppingCart(false);
    };

    const onMenuClosePress = () => {
        setShowMenu(false);
    };

    const { mutate: logout } = useLogout();

    const { mutate: login } = useLogin();

    useEffect(() => {
        setShowMenu(false);
    }, [pathname]);

    const { logo, topNavigationBarBackground } = shopInfo;

    const [headerRef, { height: headerHeight }] = useMeasure();

    useEffect(() => {
        onHeightChanged(headerHeight);
    }, [headerHeight]);

    const isApp = useSelector(state => state.member.isApp);

    const currentPage = useCurrentPage();

    const { t } = useTranslation();

    const prevInventoryAmt = useRef(cart.inventoryAmt);

    useEffect(() => {
        if ((currentPage.name === pageRoutes.product.name || currentPage.name === pageRoutes.promotion.name) && cart.inventoryAmt > prevInventoryAmt.current) {
            setShowShoppingCart(true);
        }

        prevInventoryAmt.current = cart.inventoryAmt;
    }, [cart.inventoryAmt]);


    const canGoBack = currentPage.name !== pageRoutes.shop.name;

    const generatePath = useGeneratePathFunc();

    return (
        <>
            <AdComponent />
            <ZStack
                ref={headerRef}
                height={isMobile ? '20' : '24'}
                width={'full'}
                justifyContent={'center'}
                alignItems={'center'}>
                {topNavigationBarBackground && (
                    <Image
                        rounded={false}
                        size={'full'}
                        source={{ uri: topNavigationBarBackground }}
                        alt={''}
                    />
                )}
                <HStack
                    width={'full'}
                    maxWidth={'1280px'}
                    alignSelf={'center'}
                    paddingX={'5'}
                    height={'full'}
                    justifyContent={'space-between'}>
                    <HStack space={'5'} alignItems={'center'} flex={1}>
                        {!isMobile ? (
                            <>
                                <Link to={homeLink} reloadDocument={false}>
                                    <Image
                                        variant={'logo'}
                                        source={{ uri: logo }}
                                        alt={'logo'}
                                    />
                                </Link>
                                <DesktopCategoryMenu />
                            </>
                        ) : (
                            <>
                                {canGoBack && isApp ? (
                                    <IconButton
                                        size={'6'}
                                        icon={
                                            <Icon
                                                as={Ionicons}
                                                name={'chevron-back'}
                                                size={21}
                                                color={'lightText'}
                                            />
                                        }
                                        onPress={onBackPress}
                                    />
                                ) : (
                                    <IconButton
                                        size={'6'}
                                        icon={
                                            <Icon
                                                as={FeatherIcon}
                                                name={'menu'}
                                                size={21}
                                                color={'lightText'}
                                            />
                                        }
                                        onPress={onMenuPress}
                                    />
                                )}
                            </>
                        )}
                    </HStack>
                    <HStack space={'5'} alignItems={'center'}>
                        {!isMobile && (
                            <>
                                <SearchBox
                                    onEnterPress={text => {
                                        const url = generatePath(
                                            pageRoutes.search.path,
                                            {
                                                keyword: text,
                                            },
                                        );
                                        navigate(url);
                                    }}
                                />
                                <IconButton
                                    size={'6'}
                                    icon={
                                        <Icon
                                            as={FeatherIcon}
                                            name={'star'}
                                            size={21}
                                            color={'lightText'}
                                        />
                                    }
                                    onPress={onFavoritePress}
                                />
                            </>
                        )}
                        {!isMobile && <LanguageMenuButton />}
                        {/* man */}
                        {(!isLoggedIn && !isApp) ? (
                            <IconButton
                            size={'6'}
                            icon={
                                <Icon
                                    as={FeatherIcon}
                                    name={'user'}
                                    size={21}
                                    color={'lightText'}
                                />
                            }
                            onPress={() => {
                                login({
                                    lang,
                                    shopId,
                                    redirectUrl:
                                        window.location.origin +
                                        generatePath(pageRoutes.shop.path) +
                                        '/loginSuccess?redirect=' +
                                        window.location.pathname,
                                });
                            }}
                        />
                        ) : (isLoggedIn && isApp) ? (
                            <IconButton
                                size={'6'}
                                icon={
                                    <Icon
                                        as={FeatherIcon}
                                        name={'user'}
                                        size={21}
                                        color={'lightText'}
                                    />
                                }
                                onPress={() => {
                                    navigate(ordersPath);
                                }}
                            />
                        ) : (isLoggedIn && !isApp) ? (
                            <Menu
                                trigger={triggerProps => {
                                    return <IconButton
                                        size={'6'}
                                        icon={
                                            <Icon
                                                as={FeatherIcon}
                                                name={'user'}
                                                size={21}
                                                color={'lightText'}
                                            />
                                        }
                                        {...triggerProps}
                                    />;
                                }}
                            >
                                <Menu.Item
                                    onPress={() => {
                                        navigate(ordersPath);
                                    }}>
                                    {t('My orders')}
                                </Menu.Item>
                                <Menu.Item
                                    onPress={() => {
                                        logout({
                                            lang,
                                            shopId,
                                        });
                                    }}>
                                    {t('Logout')}
                                </Menu.Item>
                            </Menu>
                        ) : (
                            <></>
                        )}
                        <ZStack size={21}>
                            {isMobile ? (
                                <IconButton
                                    size={'6'}
                                    icon={
                                        <Icon
                                            as={FeatherIcon}
                                            name={'shopping-cart'}
                                            size={21}
                                            color={'lightText'}
                                        />
                                    }
                                    onPress={onShoppingCartPress}
                                />
                            ) : (
                                <Link
                                    to={shoppingCartLink}
                                    reloadDocument={false}>
                                    <Icon
                                        as={FeatherIcon}
                                        name={'shopping-cart'}
                                        size={21}
                                        color={'lightText'}
                                    />
                                </Link>
                            )}
                            {/* {(cart && cart.inventoryAmt) && */}
                            <View
                                position={'absolute'}
                                top={-8}
                                right={-8}
                                bg={'danger.500'}
                                height={'4'}
                                width={'4'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                rounded={'full'}>
                                <Text fontSize={'xs'} color={'white'}>
                                    {cart.inventoryAmt}
                                </Text>
                            </View>
                            {/* } */}
                        </ZStack>
                    </HStack>
                </HStack>
                {isMobile && (
                    <Link to={homeLink}>
                        <Image
                            variant={'logo'}
                            width={'32'}
                            source={{ uri: logo }}
                            alt={'logo'}
                        />
                    </Link>
                )}
            </ZStack>
            <ShoppingCartModal
                visible={showShoppingCart}
                onClosePress={onShoppingCartClosePress}
                onShoppingCartPress={() => {
                    setShowShoppingCart(false);
                    navigate(shoppingCartLink);
                }}
            />
            {isMobile && (
                <MenuModal visible={showMenu} onClosePress={onMenuClosePress} />
            )}
        </>
    );
};

export default NavigationHeader;
