import moment from 'moment';
import { Input } from 'native-base';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { StyleSheet, View, Text, Image, TextInput } from 'react-native';
import { allStyles, placeholderTextColor } from './FormStyles';
import TitleLabel from './TitleLabel';
import { translate } from './FormUtils';
import 'react-datepicker/dist/react-datepicker.css';

const styles = StyleSheet.create(allStyles);

export default function FieldCalendar(props) {
    const { minDay, maxDay, startDay, endDay, startMode, endMode } = props;

    const minDate = startMode == "FIX" && startDay
        ? new Date(startDay) :
        (startMode == "DYNAMIC" && minDay ? moment().add(minDay, 'day').endOf('day').toDate() : new Date());
    const maxDate = endMode == "FIX" && endDay
        ? new Date(endDay) :
        (endMode == "DYNAMIC" && maxDay ? moment().add(maxDay, 'day').endOf('day').toDate() : null);
    
    const [date, setDate] = useState(
        props.value ? moment(props.value).toDate() : null,
    );

    const defaultPlaceholder = `${translate(['FORM', 'ENTER'])}${translate(
        props.title,
    ).toLowerCase()}`;
    const placeholder = translate(props.placeholder) || defaultPlaceholder;
    let errorPlaceholder = '';

    if (!props.title && props.error && !props.hideError) {
        errorPlaceholder = props.error;
    }

    return (
        <View
            style={[
                styles.fieldContainer,
                props.containerStyle,
                { zIndex: 1000 },
            ]}>
            <TitleLabel {...props} />
            <ReactDatePicker
                selected={date}
                minDate={minDate}
                maxDate={maxDate}
                customInput={
                    <TextInput
                        editable={props.editable ?? true}
                        style={[
                            styles.textInput,
                            { width: '100%' },
                            typeof props.value === 'string' &&
                            props.value.length > 0
                                ? {
                                      backgroundColor: '#FFFFFF',
                                      borderWidth: StyleSheet.hairlineWidth,
                                      borderColor: '#212121',
                                  }
                                : {},
                            props.style,
                        ]}
                        placeholderTextColor={
                            errorPlaceholder ? '#f43f5e' : placeholderTextColor
                        }
                    />
                }
                placeholderText={
                    errorPlaceholder ? errorPlaceholder : placeholder
                }
                calendarContainer={({ children }) => (
                    <View style={styles.datePickerContainer}>{children}</View>
                )}
                onChange={newDate => {
                    setDate(newDate);
                    props.onChange({
                        name: props.name,
                        value: newDate
                            ? moment(newDate.toUTCString()).format('YYYY-MM-DD')
                            : null,
                    });
                }}
            />
        </View>
    );
}
