import { Icon, IconButton } from 'native-base';
import React from 'react';
import Ionicons from 'react-native-vector-icons/dist/Ionicons';
import { Image } from 'react-native';

const whiteIcon = require('../assets/images/close_white.png');

export const CloseButton = ({
    onPress,
    color,
    isWhiteIcon = false,
    ...props
}) => {
    const icon = isWhiteIcon ? (
        <Image
            source={whiteIcon}
            style={{ width: '100%', height: '100%', resizeMode: 'contain' }}
        />
    ) : (
        <Icon as={Ionicons} name={'ios-close'} size={21} color={color} />
    );
    return <IconButton size={'6'} icon={icon} onPress={onPress} {...props} />;
};
