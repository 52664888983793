import { useQuery } from '@tanstack/react-query';
import { getShopOGInfo } from '../api/shop';
import { useAppContext } from '../providers/AppProvider';
import { apiRoutes } from '../Routes';
import shopApiClient from '../api/shopApiClient';
import { generatePath } from '../utils/routeUtils';
import _ from 'lodash';

export const queries = {
    shopOgInfo: ({shopShortName, shopDomain}) => ({
        queryKey: [shopShortName ? shopShortName : shopDomain],
        queryFn: () => getShopOGInfo(shopShortName, shopDomain),
    }),
    shopInfo: ({shopId, lang}) => ({
        queryKey: [shopId, lang, 'info'],
        queryMeta: {
            apiRoute: apiRoutes.shopInfo,
            params: {
                shopId,
                lang,
            },
        },
    }),
    shopLanding: ({shopId, lang}) => ({
        queryKey: [shopId, lang, 'landing'],
        queryMeta: {
            apiRoute: apiRoutes.shopLanding,
            params: {
                shopId,
                lang,
            },
        },
    }),
    shopPageContent: ({shopId, lang, page}) => ({
        queryKey: [shopId, lang, 'page', page],
        queryMeta: {
            apiRoute: apiRoutes.shopPageContent,
            params: {
                shopId,
                lang,
                page,
            },
        },
    }),
    categories: ({shopId, lang}) => ({
        queryKey: [shopId, lang, 'categories'],
        queryMeta: {
            apiRoute: apiRoutes.shopCategoryList,
            params: {
                shopId,
                lang,
            },
        }
    }),
    products: ({shopId, lang, categoryId, paging}) => ({
        queryKey: [shopId, lang, 'category', categoryId, paging],
        queryMeta: {
            apiRoute: apiRoutes.shopProductList,
            params: {
                shopId,
                lang,
                categoryId,
            },
            query: {
                ...paging,
            },
        },
    }),
    productsTotal: ({shopId, lang, categoryId}) => ({
        queryKey: [shopId, lang, 'category', categoryId],
    }),
    product: ({shopId, lang, categoryId, productId}) => ({
        queryKey: [shopId, lang, 'product', parseInt(productId)], // productId will be string if get from useParams, parse it to make sure key is correct
        queryMeta: {
            apiRoute: apiRoutes.shopProductDetail,
            params: {
                shopId,
                lang,
                categoryId,
                productId,
            },
        },
    }),
    stock: ({shopId, lang, categoryId, productId, colorId, questionAnswer}) => ({
        queryKey: [shopId, lang, 'product', productId, 'color', colorId, questionAnswer],
        queryMeta: {
            apiRoute: apiRoutes.shopProductStock,
            params: {
                shopId,
                lang,
                categoryId,
                productId,
                colorId,
            },
            query: {
                questionAnswer,
            },
        },
    }),
    productSearch: ({shopId, lang, keyword, paging}) => ({
        queryKey: [shopId, lang, 'search', keyword, paging],
        queryMeta: {
            apiRoute: apiRoutes.shopProductSearch,
            params: {
                shopId,
                lang,
            },
            query: {
                searchKeyword: keyword,
                ...paging,
            }
        },
    }),
    cart: ({shopId, lang}) => ({
        queryKey: [shopId, lang, 'cart'],
    }),
    cartDetail: ({shopId, lang, shippingMethodId, shippingCountryId, promotionCode, selectedStock = []}) => {
        const queryKey = [shopId, lang, 'cart'];

        if (selectedStock && Array.isArray(selectedStock) && selectedStock.length > 0) {
            queryKey.push(_.sortBy(selectedStock).join(','));
        }
        
        if (shippingMethodId || shippingCountryId || promotionCode) { 
            queryKey.push({ shippingMethodId, shippingCountryId, promotionCode });
        }
        return {
            queryKey,
            queryMeta: {
                apiRoute: apiRoutes.shoppingCartDetail,
                params: {
                    shopId,
                    lang,
                },
                query: {
                    shippingMethodId,
                    shippingCountryId,
                    promotionCode,
                    selectedStock,
                },
            },
        }
    },
    cartCounting: ({shopId, lang}) => ({
        queryKey: [shopId, lang, 'cart', 'counting'],
        queryMeta: {
            apiRoute: apiRoutes.shoppingCartCounting,
            params: {
                shopId,
                lang,
            },
        },
    }),
    orderList: ({shopId, lang, paging}) => ({
        queryKey: [shopId, lang, 'orders', paging],
        queryMeta: {
            apiRoute: apiRoutes.orderList,
            params: {
                shopId,
                lang,
            },
            query: {
                ...paging,
            }
        }
    }),
    orderDetail: ({shopId, lang, orderNo}) => ({
        queryKey: [shopId, lang, 'order', orderNo],
        queryMeta: {
            apiRoute: apiRoutes.orderDetail,
            params: {
                shopId,
                lang,
                orderNo,
            }
        }
    }),
    checkoutOption: ({shopId, lang}) => ({
        queryKey: [shopId, lang, 'checkoutOption'],
        queryMeta: {
            apiRoute: apiRoutes.checkoutOption,
            params: {
                shopId,
                lang,
            }
        }
    }),
    promotionDetail: ({shopId, lang, promotionId}) => ({
        queryKey: [shopId, lang, 'shopPromotion'],
        queryMeta: {
            apiRoute: apiRoutes.shopPromotion,
            params: {
                shopId,
                lang,
                promotionId,
            },
        },
    }),
    shopAddress: ({shopId, lang}) => ({
        queryKey: [shopId, lang, 'address'],
        queryMeta: {
            apiRoute: apiRoutes.shopAddress,
            params: {
                shopId,
                lang,
            }
        }
    }),
}

export const makeQuery = (query) => {
    const { queryKey, queryFn, queryMeta } = query;

    return {
        queryKey,
        queryFn: queryFn ?? makeQueryFn(queryMeta),
    };
};

export const makeQueryFn = (queryMeta) => {
    if (!queryMeta) return null;

    const { apiRoute, params, query: urlQuery } = queryMeta;

    const url = generatePath(apiRoute, params);

    if (!url) return null;

    return () => fetcher({
        url,
        params: urlQuery,
    });
};

const fetcher = async ({ url, params }) => {
    const res = await shopApiClient.get({
        url,
        params,
    });
    
    return res.data;
};

export const useFetcher = (
    query = () => {},
    params = {},
    options,
) => {
    const { shopId, lang } = useAppContext();
    const { queryKey, queryFn, queryMeta } = query({
        shopId, lang,
        ...params,
    });
    
    let _queryFn = queryFn ?? makeQueryFn(queryMeta);
    let enabled = false;

    if (typeof _queryFn === 'function') {
        enabled = true;
    } else {
        _queryFn = () => ({});
    }

    const context = useQuery({
        queryKey,
        queryFn: _queryFn,
        enabled,
        ...options,
    });

    return {
        ...context,
        data: context.data?.data ?? {},
    }
};