export const apiRoutes = {
    shopOgInfo: '/app/shopOgInfo',
    shopInfo: '/:lang/app/shopInfo/:shopId',
    shopPageContent: '/:lang/app/shopPageContent/:shopId/:page',
    shopLanding: '/:lang/app/shopLanding/:shopId',
    shopAddress: '/:lang/app/shopAddress/:shopId',
    shopCategoryList: '/:lang/app/shopCategory/:shopId',
    shopProductList: '/:lang/app/shopProduct/:shopId/:categoryId',
    shopProductSearch: '/:lang/app/shopProduct/:shopId/search',
    shopProductDetail: '/:lang/app/shopProduct/:shopId/:categoryId/:productId',
    shopProductStock:
        '/:lang/app/shopProduct/:shopId/:categoryId/:productId/:colorId',
    shoppingCartCounting: '/:lang/app/shoppingCart/:shopId/counting',
    shoppingCartDetail: '/:lang/app/shoppingCart/:shopId/detail',
    checkoutOption: '/:lang/app/checkoutOption/:shopId',
    pendingOrder: '/:lang/app/pendingOrder/:shopId',
    pendingOrderDetail: '/:lang/app/pendingOrder/:shopId/:pendingOrderNo',
    orderList: '/:lang/app/order/:shopId',
    orderDetail: '/:lang/app/order/:shopId/:orderNo',
    memberLogin: '/:lang/app/memberLogin/:shopId',
    favoriteProduct: '/:lang/app/favoriteProduct/:shopId/:productId',
    shopPromotion: '/:lang/app/shopPromotion/:shopId/:promotionId',
    payment: '/:lang/app/payment/:shopId/:pendingOrderNo',
};

export const pageRoutes = {
    root: {
        name: 'root',
        path: '/',
    },
    shop: {
        name: 'shop',
        path: '/:lang/shop/:shopShortName',
    },
    category: {
        name: 'category',
        path: '/:lang/shop/:shopShortName/category/:categoryId',
    },
    categoryPage: {
        name: 'category',
        path: '/:lang/shop/:shopShortName/category/:categoryId/page/:page',
    },
    categoryPageSorting: {
        name: 'category',
        path: '/:lang/shop/:shopShortName/category/:categoryId/page/:page/sort/:sortingMethod',
    },
    product: {
        name: 'product',
        path: '/:lang/shop/:shopShortName/category/:categoryId/product/:productId',
    },
    search: {
        name: 'searchProduct',
        path: '/:lang/shop/:shopShortName/search/:keyword',
    },
    searchPage: {
        name: 'searchProduct',
        path: '/:lang/shop/:shopShortName/search/:keyword/page/:page',
    },
    searchPageSorting: {
        name: 'searchProduct',
        path: '/:lang/shop/:shopShortName/search/:keyword/page/:page/sort/:sortingMethod',
    },
    cart: {
        name: 'cart',
        path: '/:lang/shop/:shopShortName/cart',
    },
    checkout: {
        name: 'checkout',
        path: '/:lang/shop/:shopShortName/checkout',
    },
    shipping: {
        name: 'shipping',
        path: '/:lang/shop/:shopShortName/checkout/shipping',
    },
    address: {
        name: 'address',
        path: '/:lang/shop/:shopShortName/checkout/address',
    },
    payment: {
        name: 'payment',
        path: '/:lang/shop/:shopShortName/checkout/payment',
    },
    paymentSuccess: {
        name: 'paymentSuccess',
        path: '/:lang/shop/:shopShortName/checkout/payment/success',
    },
    paymentFail: {
        name: 'paymentFail',
        path: '/:lang/shop/:shopShortName/checkout/payment/fail',
    },
    orderSuccess: {
        name: 'orderSuccess',
        path: '/:lang/shop/:shopShortName/order/:orderNo/success',
    },
    orders: {
        name: 'records',
        path: '/:lang/shop/:shopShortName/member/orders',
    },
    ordersPage: {
        name: 'records',
        path: '/:lang/shop/:shopShortName/member/orders/page/:page',
    },
    orderDetails: {
        name: 'orderDetails',
        path: '/:lang/shop/:shopShortName/member/order/:orderNo',
    },
    page: {
        name: 'page',
        path: '/:lang/shop/:shopShortName/:page',
    },
    promotion: {
        name: 'promotion',
        path: '/:lang/shop/:shopShortName/promotion/:promotionId',
    },
    tnc: {
        name: 'tnc',
        path: '/:lang/shop/:shopShortName/tnc',
    },
    privacy: {
        name: 'privacy',
        path: '/:lang/shop/:shopShortName/privacy-policy',
    },
};
