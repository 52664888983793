import { useContext, useState } from 'react';
import { createContext } from 'react';
import _ from 'lodash';

const WithSelectedStockContext = createContext(false);

const WithSelectedStockProvider = ({
  children,
}) => {
  const [withSelectedStockOnly] = useState(true);

  return (
    <WithSelectedStockContext.Provider value={withSelectedStockOnly}>
      {children}
    </WithSelectedStockContext.Provider>
  );
};

const useWithSelectedStock = () => useContext(WithSelectedStockContext);

export { WithSelectedStockProvider, useWithSelectedStock };