import {
    HStack,
    Icon,
    IconButton,
    Pressable,
    ScrollView,
    Stack,
    Text,
    VStack,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Modal, TouchableOpacity } from 'react-native';
import Ionicons from 'react-native-vector-icons/dist/Ionicons';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';
import { actions } from '../constrants/actions';
import {
    extreactCategoryIdFromAction,
    SpecialCategories,
} from '../constrants/categories';
import { useShopOGInfo } from '../providers/ShopOGInfoContext';
import { useShopCategoryList } from '../queries/categories';
import { useShopInfo } from '../queries/shop';
import { pageRoutes } from '../Routes';
import { useGeneratePathFunc, usePathMaker } from '../utils/routeUtils';
import useCurrentPage from '../utils/useCurrentPage';
import AnimatedView from './AnimatedView';
import MenuItem from './MenuItem';
import SearchBox from './SearchBox';

const AnimatedVStack = animated(VStack);

const CategoryMenuItem = ({ category }) => {
    const { name, actionTarget } = category;
    const categoryId = extreactCategoryIdFromAction(actionTarget);
    const path = usePathMaker.category(categoryId);

    return <MenuItem link={path} title={name} />;
};

const MenuModal = ({ visible = false, onClosePress = () => {} }) => {
    const { data: shopInfo } = useShopInfo();
    const { topNavigationBarLinks = [], app } = shopInfo;

    const [ref, { width: containerWidth }] = useMeasure();

    const { t } = useTranslation();

    const favoriteCategory = {
        name: t('My favorite'),
        actionTarget: `${actions.category.scheme}://${SpecialCategories.favorite.id}`,
    };

    const generatePath = useGeneratePathFunc();
    const navigate = useNavigate();

    const { data } = useShopOGInfo();
    const { supportLocales = [], shopId } = data;

    const isApp = useSelector(state => state.member.isApp);

    const showFooterContent = (!isApp || shopId !== 'bc608_donutestw');

    const currentRoute = useCurrentPage(true);

    const params = useParams();

    const fullWidth = Dimensions.get('window').width;

    const { x, opacity } = useSpring({
        x: containerWidth ? (visible ? 0 : -containerWidth) : -fullWidth,
        opacity: visible ? 1 : 0,
        config: { duration: 300 },
    });

    const [_visible, set_visible] = useState(visible);

    useEffect(() => {
        if (visible) {
            set_visible(true);
        } else {
            setTimeout(() => {
                set_visible(false);
            }, 300);
        }
    }, [visible]);

    const {
        data: { categories = [] },
    } = useShopCategoryList();

    return (
        <Modal visible={_visible} transparent={true}>
            <AnimatedView
                style={{
                    top: 0,
                    left: 0,
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    opacity,
                }}
            />
            <Pressable
                width={'1/4'}
                position={'absolute'}
                top={0}
                right={0}
                height={'full'}
                onPress={onClosePress}
            />
            <AnimatedVStack
                ref={ref}
                width={'3/4'}
                height={'full'}
                style={{ x }}
                backgroundColor={'white'}
                padding={'5'}
                space={'2'}>
                <Stack direction={'row-reverse'}>
                    <IconButton
                        size={'6'}
                        icon={
                            <Icon
                                as={Ionicons}
                                name={'ios-close'}
                                size={21}
                                color={'lightText'}
                            />
                        }
                        onPress={onClosePress}
                    />
                </Stack>
                <SearchBox
                    onEnterPress={text => {
                        const url = generatePath(pageRoutes.search.path, {
                            keyword: text,
                        });

                        navigate(url);
                    }}
                />
                <ScrollView showsVerticalScrollIndicator={false}>
                    <VStack space={'2'}>
                        <CategoryMenuItem category={favoriteCategory} />
                        {categories.map((item, x) => {
                            if (item.subCategories) {
                                return (
                                    <MenuItem
                                        key={x}
                                        title={item.name}
                                        children={item.subCategories.map(
                                            (subItem, y) => {
                                                return (
                                                    <CategoryMenuItem
                                                        key={y}
                                                        category={subItem}
                                                    />
                                                );
                                            },
                                        )}
                                    />
                                );
                            } else {
                                return (
                                    <CategoryMenuItem key={x} category={item} />
                                );
                            }
                        })}
                    </VStack>
                    {showFooterContent && (
                        <VStack marginTop={'5'}>
                            <MenuItem
                                actionTarget={'page://membership'}
                                title={t('Membership')}
                                isSmall
                            />
                            <MenuItem
                                actionTarget={'page://faq'}
                                title={t('Faq')}
                                isSmall
                            />
                            <MenuItem
                                actionTarget={'page://about-us'}
                                title={t('Us')}
                                isSmall
                            />
                            <MenuItem
                                actionTarget={'page://tnc'}
                                title={t('Terms and conditions')}
                                isSmall
                            />
                            <MenuItem
                                actionTarget={'page://privacy-policy'}
                                title={t('Privacy policy')}
                                isSmall
                            />
                        </VStack>
                    )}
                </ScrollView>
                {!isApp && (
                    <HStack flexWrap={'wrap'} justifyContent={'center'}>
                        {supportLocales.map((locale, index) => {
                            const url = generatePath(currentRoute.path, {
                                ...params,
                                lang: locale,
                            });

                            return (
                                <TouchableOpacity
                                    key={locale}
                                    onPress={() => {
                                        navigate(url);
                                    }}>
                                    <Text>
                                        {t(locale)}
                                        {index < supportLocales.length - 1 && (
                                            <Text>{' | '}</Text>
                                        )}
                                    </Text>
                                </TouchableOpacity>
                            );
                        })}
                    </HStack>
                )}
            </AnimatedVStack>
        </Modal>
    );
};

export default MenuModal;
