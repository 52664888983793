import React from 'react';
import { StyleSheet, View } from 'react-native';
import { allStyles } from './FormStyles';
import { renderField } from './SimpleForm';
import TitleLabel from './TitleLabel';

const styles = StyleSheet.create(allStyles);

const FieldGroupped = props => {
    const {
        fields,
        zIndex,
        getValueByName,
        onChange,
        getItemsByName,
        getErrorByName,
        onBlur,
    } = props;

    return (
        <View style={[styles.fieldContainer, props.containerStyle]}>
            <TitleLabel {...props} />
            {fields.map((childProps, index) => {
                return (
                    <React.Fragment key={index}>
                        {renderField(
                            childProps,
                            100 - index,
                            getValueByName,
                            onChange,
                            getItemsByName,
                            getErrorByName,
                            onBlur,
                        )}
                    </React.Fragment>
                );
            })}
        </View>
    );
};

export default FieldGroupped;
