import { StyleSheet } from 'react-native';

export const placeholderTextColor = '#A8A8A8';
export const backgroundColor = '#F8F8F8';
export const fieldHeight = 45;
export const borderRadius = 14;
export const textFontSize = 16;
export const subTextFontSize = 14;
export const titleFontSize = 16;
export const subTitleFontSize = 14;
export const borderColor = '#9D9D9D';
export const borderWidth = StyleSheet.hairlineWidth;
export const textColor = '#262626';
export const fieldPadding = 24;

export const allStyles = {
    fieldContainer: {
        flexDirection: 'column',
        marginVertical: 8,
        position: 'relative',
    },
    fieldRow: {
        flexDirection: 'row',
    },
    title: {
        color: textColor,
        fontSize: textFontSize,
        fontWeight: 'bold',
        marginVertical: 4,
        marginBottom: 12,
    },
    red: {
        fontSize: textFontSize,
        fontWeight: 'normal',
        color: '#f43f5e',
    },
    tip: {
        fontSize: subTextFontSize,
        fontWeight: 'normal',
        color: '#BDBEC4',
    },
    textInput: {
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderRadius,
        backgroundColor: backgroundColor,
        fontSize: textFontSize,
        color: '#212121',
        height: fieldHeight,
        borderColor,
        borderWidth,
        paddingHorizontal: fieldPadding,
    },
    placeholderStyle: {
        fontSize: textFontSize,
        color: placeholderTextColor,
    },
    dropDownStyle: {
        borderRadius,
        backgroundColor: backgroundColor,
    },
    dropDownLabel: {
        color: '#212121',
        fontSize: textFontSize,
    },
    seperator: {
        height: StyleSheet.hairlineWidth,
        backgroundColor: placeholderTextColor,
    },
    photoContainer: {
        marginVertical: 8,
    },
    photoButton: {
        height: 120,
        width: 120,
        borderRadius,
        backgroundColor: '#F2F2F2',
        alignItems: 'center',
        justifyContent: 'center',
    },
    photoReview: {
        height: 120,
        width: 120,
        borderRadius,
    },
    addIcon: {
        width: 16,
        height: 16,
        marginBottom: 4,
    },
    barcodeButton: {
        paddingRight: 6,
        height: fieldHeight - 8,
        marginBottom: 4,
        marginRight: 4,
        borderRadius,
        backgroundColor: '#212121',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 1000,
    },
    barcodeButtonTitle: {
        color: '#FFFFFF',
    },
    barcodeIcon: {
        height: fieldHeight - 20,
    },
    countryCodeContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        position: 'absolute',
        bottom: 0,
        left: 8,
        zIndex: 1000,
        width: 85,
    },
    text: {
        fontSize: textFontSize,
        color: textColor,
    },
    countryCodeContainerForQuery: {
        width: 95,
        justifyContent: 'center',
        marginRight: 14,
    },
    phoneInputForQuery: {
        flex: 1,
        backgroundColor: 'transparent',
        borderColor: '#ACACAC',
        borderWidth: StyleSheet.hairlineWidth,
    },
    modalBackdrop: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        // maxWidth:428,
    },
    modalContainer: {
        width: '90%',
        maxWidth: 428,
        maxHeight: '60%',
        paddingTop: 16,
        backgroundColor: 'white',
        borderRadius: 16,
    },
    modalTitle: {
        color: '#212121',
        fontSize: textFontSize,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    modalSelectItemContainer: {
        borderBottomColor: '#707070',
        borderBottomWidth: StyleSheet.hairlineWidth,
        paddingVertical: 16,
        flexDirection: 'row',
        alignItems: 'center',
    },
    modalSelectItemBubble: {
        borderRadius: 7,
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: '#212121',
        width: 14,
        height: 14,
        marginRight: 8,
    },
    modalSelectItemBubbleSeleted: {
        borderRadius: 7,
        backgroundColor: '#212121',
        width: 14,
        height: 14,
        marginRight: 8,
    },
    selectContainer: {
        borderRadius,
        backgroundColor: backgroundColor,
        height: fieldHeight,
        alignItems: 'center',
        paddingHorizontal: fieldPadding,
        flexDirection: 'row',
        borderColor,
        borderWidth,
    },
    selectText: {
        color: '#212121',
        fontSize: textFontSize,
    },
    selectIcon: {
        width: 8,
        height: 8,
    },
    checkboxContainer: {
        flexDirection: 'row',
        marginTop: 5,
        marginLeft: 5,
        flex: 1,
    },
    checkbox: {
        borderColor: '#707070',
        borderWidth: StyleSheet.hairlineWidth,
        borderRadius: 5,
        width: 18,
        height: 18,
        marginRight: 5,
    },
    checked: {
        backgroundColor: '#262626',
        borderColor: '#262626',
    },
    datePickerContainer: {
        backgroundColor,
        borderColor: '#707070',
        borderWidth: StyleSheet.hairlineWidth,
        borderRadius: 5,
    },
};
