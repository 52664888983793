import { View } from 'native-base';
import React from 'react';

const Separator = () => {
    return (
        <View
            height={'0'}
            borderBottomWidth={'1'}
            borderBottomColor={'warmGray.300'}
        />
    );
};

export default Separator;
