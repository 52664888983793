import { Pressable, useTheme } from 'native-base';
import React from 'react';
import { Link } from 'react-router-dom';
import { actions, extractFromAction } from '../constrants/actions';
import { SpecialCategories } from '../constrants/categories';
import { pageRoutes } from '../Routes';
import { useGeneratePathFunc } from '../utils/routeUtils';
import _ from 'lodash';
import { useSelector } from 'react-redux';

const openInappBrowser = (url) => {
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
            action: 'openInappBrowser',
            url,
        }));
    }
}

const ActionLink = ({ action = '_self', actionTarget = '', children, useHtmlButton, ...props }) => {
    let url;

    const isApp = useSelector(state => state.member.isApp);

    const { scheme, id } = extractFromAction(actionTarget);

    const generatePath = useGeneratePathFunc();

    const _action = action === 'self' ? '_self' : action === 'blank' ? '_blank' : action;

    const theme = useTheme();

    if (scheme === actions.category.scheme) {
        url = generatePath(pageRoutes.category.path, {
            categoryId: id,
        });
    } else if (scheme === actions.product.scheme) {
        url = generatePath(pageRoutes.product.path, {
            categoryId: SpecialCategories.all.id,
            productId: id,
        });
    } else if (scheme === actions.page.scheme) {
        url = generatePath(pageRoutes.page.path, {
            page: id,
        });
    } else if (scheme === actions.search.scheme) {
        url = generatePath(pageRoutes.search.path, {
            keyword: id,
        });
    } else if (scheme === actions.promotion.scheme) {
        url = generatePath(pageRoutes.promotion.path, {
            promotionId: id,
        });
    } else if (scheme === 'http' || scheme === 'https') {
        const action = () => {
            if (isApp) {
                openInappBrowser(actionTarget);
            } else if (_action === '_self') {
                window.location.replace(actionTarget);
            } else {
                window.open(actionTarget);
            }
        };

        if (useHtmlButton) {
            return (
                <button 
                    type={'button'} 
                    style={{ 
                        border: 0, 
                        borderRadius: 0, 
                        padding: 0, 
                        margin: 0,
                        cursor: 'pointer',
                        backgroundColor: 'transparent',
                        boxShadow: 'none', 
                        color: theme.colors.lightText,
                        fontSize: theme.fontSizes.md,
                    }} 
                    onClick={action}
                >
                    {children}
                </button>
            );
        }
        
        return (
            <Pressable
                style={{ ...props.style }}
                onPress={action}>
                {children}
            </Pressable>
        );
    }

    if (url) {
        return (
            <Link
                to={url}
                target={_action}
                style={{ display: 'flex', flexDirection: 'column', ...props.style }}>
                {children}
            </Link>
        );
    }

    return <>{children}</>;
};

export default ActionLink;
