import { useQueryClient } from '@tanstack/react-query';
import {
    Button,
    Center,
    HStack,
    Icon,
    IconButton,
    Pressable,
    Text,
    View,
    VStack,
} from 'native-base';
import React, { useEffect } from 'react';
import { useState } from 'react';
import ReactStickyBox from 'react-sticky-box';
import { Dimensions, Modal, ScrollView } from 'react-native';
import Feather from 'react-native-vector-icons/dist/Feather';
import Ionicons from 'react-native-vector-icons/dist/Ionicons';
import { animated, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';
import { queries } from '../queries';
import { useCartDetail } from '../queries/cart';
import { formatPrice } from '../utils/Utils';
import AnimatedView from './AnimatedView';
import ShoppingCartItemTable from './ShoppingCartItemTable';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../utils/hooks';
import { useAppContext } from '../providers/AppProvider';
import Loading from './Loading';
import { FormatPrice } from './FormatPrice';

const AnimatedVStack = animated(VStack);

const ShoppingCartModal = ({
    visible = false,
    onClosePress = () => {},
    onShoppingCartPress = () => {},
}) => {
    const { shopId, lang } = useAppContext();
    const { t } = useTranslation();
    const { data: cart, isLoading, isPreviousData, isFetching, } = useCartDetail();
    const isCartLoading = isLoading || isPreviousData || isFetching;

    const { inventories, subtotal } = cart;

    const queryClient = useQueryClient();

    const [_visible, set_visible] = useState(visible);

    const isMobile = useIsMobile();

    useEffect(() => {
        if (visible) {
            set_visible(true);
        } else {
            setTimeout(() => {
                set_visible(false);
            }, 300);
        }
    }, [visible]);

    const [ref, { width: containerWidth }] = useMeasure();

    const fullWidth = Dimensions.get('window').width;

    const { x, opacity } = useSpring({
        x: visible && containerWidth ? fullWidth - containerWidth : fullWidth,
        opacity: visible ? 1 : 0,
        config: { duration: 300 },
    });

    return (
        <Modal visible={_visible} transparent={true}>
            <>
                <AnimatedView
                    style={{
                        top: 0,
                        left: 0,
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        opacity,
                    }}
                />
                <Pressable
                    width={isMobile ? '1/4' : '1/2'}
                    position={'absolute'}
                    top={0}
                    left={0}
                    height={'full'}
                    onPress={onClosePress}
                />
                <AnimatedVStack
                    ref={ref}
                    height={'full'}
                    width={isMobile ? '3/4' : '1/2'}
                    backgroundColor={'white'}
                    style={{ x }}>
                    <HStack
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        padding={'5'}>
                        <Text bold>{t('Shopping cart')}</Text>
                        <IconButton
                            size={'6'}
                            icon={
                                <Icon
                                    as={Ionicons}
                                    name={'ios-close'}
                                    size={21}
                                    color={'lightText'}
                                />
                            }
                            onPress={onClosePress}
                        />
                    </HStack>
                    <ScrollView>
                    <VStack flexGrow={1} paddingX={'5'}>
                        {inventories && inventories.length === 0 && (
                            <Center>
                                <Text>{t('Shopping cart is empty')}</Text>
                            </Center>
                        )}
                        <ShoppingCartItemTable
                            data={inventories}
                            hideTitle={true}
                            dropable={true}
                        />
                    </VStack>
                    </ScrollView>
                    <ReactStickyBox bottom={true} style={{ zIndex: 100 }}>
                    <VStack
                        space={'5'}
                        borderTopWidth={'1'}
                        borderTopColor={'warmGray.300'}
                        padding={'5'}>
                        <HStack justifyContent={'space-between'}>
                            <Text>{t('Subtotal')}</Text>
                            <Text>{<FormatPrice price={subtotal}/>}</Text>
                        </HStack>
                        <Button
                            variant={'fullRoundedFill'}
                            leftIcon={
                                <Icon as={Feather} name={'shopping-cart'} />
                            }
                            onPress={onShoppingCartPress}>
                            {t('VIEW_CART')}
                        </Button>
                    </VStack>
                    </ReactStickyBox>
                    {isCartLoading && <Loading />}
                </AnimatedVStack>
            </>
        </Modal>
    );
};

export default ShoppingCartModal;
