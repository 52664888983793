import { Button, extendTheme, HStack, Icon, Menu, NativeBaseProvider, Pressable, Text } from 'native-base';
import React, { useEffect } from 'react';
import { useState } from 'react';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { useTranslation } from 'react-i18next';

export const SortingMethod = {
    priceDesc: 'priceDesc',
    priceAsc: 'priceAsc',
    timeDesc: 'timeDesc',
    timeAsc: 'timeAsc',
    recommended: 'recommended',
};

const SortingMethodList = [
    SortingMethod.recommended,
    SortingMethod.timeDesc,
    SortingMethod.timeAsc,
    SortingMethod.priceAsc,
    SortingMethod.priceDesc,
];

const SortingMethodSelector = ({
    showLabel,
    notSelected = false,
    sortingMethodList = SortingMethodList,
    selectedSortingMethod = SortingMethod.recommended,
    selectorId = null,
    onSortingMethodChanged = () => {},
}) => {
    const { t } = useTranslation();

    return (
        <HStack space={'5'} height={selectorId ? null : '34px'} minWidth={280}>
            {showLabel && <Text>{t('Sorting')}</Text>}
            <Menu
                trigger={triggerProps => {
                    return (
                        <Pressable
                            alignItems={'center'}
                            flexDirection={'row'}
                            borderWidth={'1px'}
                            borderColor={'lightText'}
                            rounded={'full'}
                            paddingX={'4'}
                            minWidth={'40'}
                            height={'37'}
                            flex={1}
                            {...triggerProps}>
                            <Text fontSize={'14px'} flex={1}>
                                {t(selectedSortingMethod)}
                            </Text>
                            <Icon
                                as={FeatherIcon}
                                name={'chevron-down'}
                                color={'lightText'}
                            />
                        </Pressable>
                    );
                }}
            >
                { notSelected ? 
                    <Menu.Item 
                        key='0' 
                        onPress={() => {
                            onSortingMethodChanged(selectorId, {id: '', label: t('SELECT_ALL')});
                        }}>
                            {t('SELECT_ALL')}
                        </Menu.Item>
                    : null
                }
                {sortingMethodList.map(sortingMethod => {
                    if(typeof sortingMethod === 'string'){
                        return (
                            <Menu.Item
                                key={sortingMethod}
                                onPress={() => {
                                    onSortingMethodChanged(sortingMethod);
                                }}>
                                {t(sortingMethod)}
                            </Menu.Item>
                        )
                    } else if (typeof sortingMethod === 'object'){
                        return (
                            <Menu.Item
                                key={sortingMethod.id}
                                onPress={() => {
                                    onSortingMethodChanged(selectorId, {id: sortingMethod.id, label: sortingMethod.label});
                                }}>
                                {t(sortingMethod.label)}
                            </Menu.Item>
                        )
                    }
                }
                )}
            </Menu>
        </HStack>
    );
};

export default SortingMethodSelector;
