import React, { useState } from 'react';
import {
    Image,
    Modal,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import { allStyles } from './FormStyles';
import { useTranslateFunc } from './FormUtils';
import TitleLabel from './TitleLabel';

const styles = StyleSheet.create(allStyles);

export default function FieldSelectModal(props) {
    const [visible, setVisible] = useState(false);

    const temp = props.getItemsByName(props.name);
    const items = Array.isArray(temp) ? temp : [];
    const message = typeof temp === 'string' ? temp : '';

    let selectedItem = {
        label: '',
        value: '',
    };

    if (items) {
        const found = items.find(item => {
            if (item.value === props.value) {
                return true;
            }
            return false;
        });
        if (found) {
            selectedItem = found;
        }
    }

    const translate = useTranslateFunc();

    let errorPlaceholder = '';

    if (!props.title && props.error && !props.hideError) {
        errorPlaceholder = props.error;
    }

    return (
        <View style={[styles.fieldContainer, props.containerStyle]}>
            {errorPlaceholder ? (
                <Text
                    style={[
                        styles.red,
                        {
                            position: 'absolute',
                            top: -20,
                            left: 25,
                            fontSize: 14,
                        },
                    ]}>
                    {errorPlaceholder}
                </Text>
            ) : (
                <></>
            )}
            <TitleLabel {...props} />
            <TouchableOpacity
                disabled={(props?.editable === false)}
                style={[
                    styles.selectContainer,
                    props?.editable === false
                    ? {
                        opacity: 0.7,
                    } : (
                    typeof selectedItem.label === 'string' &&
                    selectedItem.label.length > 0
                    ) ? {
                        backgroundColor: '#FFFFFF',
                        borderWidth: StyleSheet.hairlineWidth,
                        borderColor: '#212121',
                    } : {},
                ]}
                onPress={() => {
                    setVisible(true);
                }}>
                <Text
                    style={[
                        styles.placeholderStyle,
                        { flexGrow: 1 },
                        typeof selectedItem.label === 'string' &&
                        selectedItem.label.length > 0 && {
                            color: '#262626',
                        },
                    ]}>
                    {selectedItem.label.length > 0
                        ? selectedItem.label
                        : translate(props.placeholder)}
                </Text>
                <Image
                    style={styles.selectIcon}
                    resizeMode={'contain'}
                    source={require('../../assets/images/awesome-sort-down.png')}
                    alt={''}
                />
            </TouchableOpacity>
            <Modal visible={visible} transparent={true} animationType={'fade'}>
                <TouchableOpacity
                    style={styles.modalBackdrop}
                    activeOpacity={1}
                    onPress={() => {
                        setVisible(false);
                    }}>
                    <View style={styles.modalContainer}>
                        <Text style={styles.modalTitle}>
                            {props.title
                                ? `${translate(['FORM', 'ENTER'])}${translate(
                                    props.title,
                                )}`
                                : translate(props.placeholder)}
                        </Text>
                        <ScrollView
                            style={{ marginTop: 16 }}
                            contentContainerStyle={{
                                paddingHorizontal: 20,
                                paddingBottom: 20,
                            }}
                            showsVerticalScrollIndicator={false}>
                            {message.length > 0 && (
                                <Text
                                    style={[
                                        styles.text,
                                        { textAlign: 'center' },
                                    ]}>
                                    {message}
                                </Text>
                            )}
                            {items.map((item, i) => (
                                <TouchableOpacity
                                    key={i}
                                    style={styles.modalSelectItemContainer}
                                    onPress={() => {
                                        setVisible(false);
                                        props.onChange({
                                            name: props.name,
                                            value: item.value,
                                        });
                                    }}>
                                    <View style={{ flexGrow: 1 }}>
                                        <Text style={styles.title}>
                                            {item.label}
                                        </Text>
                                        {item.subLabel && (
                                            <Text style={styles.text}>
                                                {item.subLabel}
                                            </Text>
                                        )}
                                    </View>
                                    <View
                                        style={
                                            props.value === item.value
                                                ? styles.modalSelectItemBubbleSeleted
                                                : styles.modalSelectItemBubble
                                        }
                                    />
                                </TouchableOpacity>
                            ))}
                        </ScrollView>
                    </View>
                </TouchableOpacity>
            </Modal>
        </View>
    );
}
