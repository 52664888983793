import { FieldType } from '../../components/Form/SimpleForm';

export const LOAD = 'LOAD';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DEDUCE_PRODUCT = 'DEDUCE_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';

export const USE_COUPON = 'USE_COUPON';
export const UNUSE_COUPON = 'UNUSE_COUPON';

export const UPDATE_FORM = 'UPDATE_FORM';
export const RESET_FORM = 'RESET_FORM';
export const SAVE_PENDING_ORDER_NO = 'SAVE_PENDING_ORDER_NO';
export const DELETE_PENDING_ORDER_NO = 'DELETE_PENDING_ORDER_NO';

export const SELECT_STOCK = 'SELECT_STOCK';
export const UNSELECT_STOCK = 'UNSELECT_STOCK';
export const RESET_SELECTED_STOCK = 'RESET_SELECTED_STOCK';

export const FormFieldName = {
    customer: {
        name: 'customerName',
        surname: 'customerSurname',
        counrtyCode: 'customeCountryCode',
        phone: 'customerPhone',
        email: 'customerEmail',
    },
    saveAddress: 'saveAddress',
    country: 'country',
    addressLine1: 'addressLine1',
    addressLine2: 'addressLine2',
    area: 'area',
    sameContact: 'sameContact',
    recipient: {
        name: 'recipientName',
        surname: 'recipientSurname',
        counrtyCode: 'recipientCountryCode',
        phone: 'recipientPhone',
        email: 'recipientEmail',
    },
    preorder: 'preorder',
    expectDeliveryDate: 'expectDeliveryDate',
    expectDeliveryPeriod: 'expectDeliveryPeriod',
    promotionCode: 'promotionCode',
    skipShipping: 'skipShipping',
};

export const SET_DELIVERY_OPTION = 'SET_DELIVERY_OPTION';
export const SET_DELIVERY_OPTION_SUBOPTION = 'SET_DELIVERY_OPTION_SUBOPTION';
export const SET_DELIVERY_OPTION_TEXT = 'SET_DELIVERY_OPTION_TEXT';

export const PaymentType = {
    creditCard: 'creditCard',
    payme: 'payme',
    alipay: 'alipay',
};

export const SET_PAYMENT_OPTION = 'SET_PAYMENT_OPTION';

export const DROP_SHOPPING_CART = 'DROP_SHOPPING_CART';

export const FIELDS = {
    name: {
        title: 'FORM.Name',
        name: FormFieldName.customer.name,
        fieldType: FieldType.text,
        required: true,
    },
    code: {
        title: 'FORM.Country code',
        name: FormFieldName.customer.counrtyCode,
        fieldType: FieldType.countryCode,
        placeholder: '+852',
        required: true,
    },
    phone: {
        title: 'FORM.Phone number',
        name: FormFieldName.customer.phone,
        fieldType: FieldType.phone,
        required: true,
    },
    email: {
        title: 'FORM.Email address',
        name: FormFieldName.customer.email,
        fieldType: FieldType.email,
        required: false,
    },
    address: {
        title: 'FORM.Delivery address',
        fieldType: FieldType.grouped,
        sideCheckbox: {
            title: 'FORM.Save address',
            name: FormFieldName.saveAddress,
        },
    },
    addressWithoutSave: {
        title: 'FORM.Delivery address',
        fieldType: FieldType.grouped,
    },
    country: {
        title: 'FORM.Country or region',
        name: FormFieldName.country,
        placeholder: 'FORM.Country or region',
        fieldType: FieldType.selectModal,
        required: true,
    },
    countryFixed: {
        name: FormFieldName.country,
        placeholder: 'FORM.Country or region',
        fieldType: FieldType.selectModal,
        required: true,
        editable: false,
    },
    area: {
        name: FormFieldName.area,
        placeholder: 'FORM.Area',
        fieldType: FieldType.selectModal,
        required: true,
    },
    addressLine1: {
        name: FormFieldName.addressLine1,
        placeholder: 'FORM.Address line 1',
        fieldType: FieldType.text,
        required: true,
    },
    addressLine2: {
        name: FormFieldName.addressLine2,
        placeholder: 'FORM.Address line 2',
        fieldType: FieldType.text,
    },
    recipient: {
        title: 'FORM.Recipient information',
        fieldType: FieldType.grouped,
        sideCheckbox: {
            title: 'FORM.Same as above',
            name: FormFieldName.sameContact,
        },
    },
    recipientName: {
        name: FormFieldName.recipient.name,
        placeholder: 'FORM.Name',
        fieldType: FieldType.text,
        hideIf: {
            name: FormFieldName.sameContact,
            value: true,
        },
        required: true,
    },
    recipientCode: {
        name: FormFieldName.recipient.counrtyCode,
        placeholder: 'FORM.Country code',
        fieldType: FieldType.countryCode,
        hideIf: {
            name: FormFieldName.sameContact,
            value: true,
        },
        required: true,
    },
    recipientPhone: {
        name: FormFieldName.recipient.phone,
        placeholder: 'FORM.Phone number',
        fieldType: FieldType.text,
        hideIf: {
            name: FormFieldName.sameContact,
            value: true,
        },
        required: true,
    },
    recipientEmail: {
        name: FormFieldName.recipient.email,
        placeholder: 'FORM.Email address',
        fieldType: FieldType.text,
        hideIf: {
            name: FormFieldName.sameContact,
            value: true,
        },
        required: false,
    },
    preorder: {
        title: 'FORM.preorder',
        name: FormFieldName.preorder,
        fieldType: FieldType.checkbox,
    },
    expectDeliveryDate: {
        title: 'FORM.delivery_date',
        name: FormFieldName.expectDeliveryDate,
        fieldType: FieldType.calendar,
        hideIf: {
            name: FormFieldName.preorder,
            value: false,
        },
        required: true,
    },
    expectDeliveryPeriod: {
        title: 'FORM.delivery_time',
        name: FormFieldName.expectDeliveryPeriod,
        fieldType: FieldType.selectModal,
        hideIf: {
            name: FormFieldName.preorder,
            value: false,
        },
        required: true,
    },
};
