import React from 'react';
import { StyleSheet, TextInput, View } from 'react-native';
import { allStyles, placeholderTextColor } from './FormStyles';
import { translate } from './FormUtils';
import TitleLabel from './TitleLabel';

const styles = StyleSheet.create(allStyles);

export default function FieldText(props) {
    const defaultPlaceholder = `${translate(['FORM', 'ENTER'])}${translate(
        props.title,
    ).toLowerCase()}`;
    const placeholder = translate(props.placeholder) || defaultPlaceholder;

    let errorPlaceholder = '';

    if (!props.title && props.error && !props.hideError) {
        errorPlaceholder = props.error;
    }

    return (
        <View style={[styles.fieldContainer, props.containerStyle]}>
            <TitleLabel {...props} />
            <TextInput
                editable={props.editable ?? true}
                style={[
                    styles.textInput,
                    typeof props.value === 'string' && props.value.length > 0
                        ? {
                              backgroundColor: '#FFFFFF',
                              borderWidth: StyleSheet.hairlineWidth,
                              borderColor: '#212121',
                          }
                        : {},
                    props.style,
                ]}
                value={props.value || ''}
                placeholderTextColor={
                    errorPlaceholder ? '#f43f5e' : placeholderTextColor
                }
                keyboardType={props.keyboardType}
                placeholder={errorPlaceholder ? errorPlaceholder : placeholder}
                onChange={e => {
                    props.onChange({
                        name: props.name,
                        value: e.nativeEvent.text,
                    });
                }}
                onBlur={() => {
                    props.onBlur(props.name);
                }}
            />
        </View>
    );
}
