import { Text } from 'native-base';
import React from 'react';
import { useMemo } from 'react';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import { useSelector } from 'react-redux';
import ActionLink from './ActionLink';

const parseOptions = {
    replace: ({ name, attribs, children, type, data, parent }) => {
        if (type === 'text' && parent && parent.name === 'a') {
            return (
                <Text>{data}</Text>
            );
        } else if (name === 'a') {
            const { href, target } = attributesToProps(attribs);
            if (href) {
                return (
                    <ActionLink
                        useHtmlButton={true}
                        actionTarget={href} 
                        action={target}
                    >
                        {domToReact(children, parseOptions)}
                    </ActionLink>
                );
            }
        }
    }
}

const HtmlText = ({ content = '', ...props }) => {
    const isApp = useSelector(state => state.member.isApp);

    const parsedContent = useMemo(() => {
        return parse(content.replace(/\\n/g, '<br>'), isApp ? parseOptions : null);
    }, [content, isApp]);

    return <Text {...props}>{parsedContent}</Text>;
};

export default HtmlText;
