import { useShopInfo } from '../queries/shop';
import { useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { Text, View } from 'native-base';
import _ from 'lodash';

export const FormatPrice = ({ price, memberPoint, discountPrice, memberPointPrice, usePoints = false }) => {
    const { data: shopInfo } = useShopInfo();
    const { currency } = shopInfo;
    const { t } = useTranslation();
    const formatter = useMemo(
        () => {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: currency,  minimumFractionDigits: (currency=='TWD'?0:2) });
        },[]
    );
    const priceLabel = usePoints ? (
        <>
            <Text>
                {memberPoint}{t('POINTS')}
                {_.isNumber(memberPointPrice) && memberPointPrice > 0 && ` + ${formatter.format(memberPointPrice)}`}
            </Text>
        </>
    ) : (_.isNumber(discountPrice) && _.isNumber(price) && discountPrice !== price) ? (
        <>
            <Text textDecorationLine={'line-through'} textDecorationColor={'lightText'} color={'muted.400'}>{`${formatter.format(price)}`}</Text>
            <Text>{` ${formatter.format(discountPrice)}`}</Text>
        </>
    ) : (_.isNumber(price)) ? (
        <Text>{`${formatter.format(price)}`}</Text>
    ) : (
        <Text></Text>
    );
    return (
        <>
            {priceLabel}
        </>
    );
};

export default FormatPrice;