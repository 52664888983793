import { useLocalStorage } from 'usehooks-ts';
import { useAppContext } from '../providers/AppProvider';

export const loginKey = (shopId) => `${shopId}_isLoggedIn`;

export const useIsLoggedIn = () => {
    const { shopId } = useAppContext();
    const [isLoggedIn, setIsLoggedIn] = useLocalStorage(
        loginKey(shopId),
        false,
    );

    return isLoggedIn;
};

export const useSetIsLoggedIn = () => {
    const { shopId } = useAppContext();
    const [isLoggedIn, setIsLoggedIn] = useLocalStorage(
        loginKey(shopId),
        false,
    );

    return setIsLoggedIn;
};
