import { Text } from "native-base";
import i18n from '../i18n';
var Buffer = require('buffer/').Buffer;

export const formatPrice = (price, prefix = '$') => {
    if (typeof price === 'number') {
        if (price >= 0) {
            return `${prefix}${price.toLocaleString('en-US', {
                minimumFractionDigits: 2,
            })}`;
        } else {
            return `-${prefix}${(price * -1).toLocaleString('en-US', {
                minimumFractionDigits: 2,
            })}`;
        }
    }
    return '';
};

export const formatDiscount = (price, discountPrice, prefix = '$') => {
    if (typeof price === 'number' &&
        typeof discountPrice === 'number' &&
        price !== discountPrice
    ) {
        return (
            <>
                <Text textDecorationLine={'line-through'} textDecorationColor={'lightText'} color={'muted.400'}>{formatPrice(price, prefix)}</Text>
                <Text>{` ${formatPrice(discountPrice, prefix)}`}</Text>
            </>
        );
    }

    return formatPrice(price || discountPrice, prefix);
};

export const formatPoints = (points, prefix = '') => {
    if (typeof points === 'number') {
        return `${prefix}${points.toLocaleString('en-US', {
            minimumFractionDigits: 0,
        })}分`;
    }
    return '';
};

export function flatDeep(arr, d = 1) {
    if (!Array.isArray(arr)) {
        return [arr];
    }
    return d > 0
        ? arr.reduce((acc, val) => acc.concat(flatDeep(val, d - 1)), [])
        : arr.slice();
}

export const getMaxQuantityOfProduct = ({
    isUnlimitStock,
    remainStock,
    maxQtyPerTransaction,
    remainQtyPerTransaction,
}) => {
    let list = [];

    if (!isUnlimitStock && typeof remainStock === 'number') {
        list.push(remainStock);
    }

    if (typeof maxQtyPerTransaction === 'number') {
        list.push(maxQtyPerTransaction);
    }

    if (typeof remainQtyPerTransaction === 'number') {
        list.push(remainQtyPerTransaction);
    }

    return Math.min(...list);
};

// response.data.[errorMsgs].message
// error.response.data.[errorMsgs].message

export const getErrorMessage = ({
    response, // api response
    error, // axios error
    defaultMessage = '',
}) => {
    let message = defaultMessage;

    const _response = error ? error?.response : response

    if (_response?.data?.errorMsgs) {
        message = _.chain(_response.data.errorMsgs).map('message').join('\n').value();
    }

    return message;
};

export const getPaymentErrorMessage = ({
    response, // api response
    error, // axios error
    defaultMessage = '',
    extraInfo,
}) => {
    let message = getErrorMessage({
        response,
        error,
        defaultMessage,
    });

    const _extraInfo = extraInfo ? extraInfo : error ? error?.response?.data?.extraInfo : response?.data?.data?.extraInfo;
    
    let json = {}

    try {
        const jsonString = _extraInfo ? Buffer.from(_extraInfo, 'base64').toString() : '';
        if (jsonString.length > 0) {
            json = JSON.parse(jsonString)
        }
    } catch (error) {
        
    }

    const { payment, errorCode } = json;

    if (payment === 'GLOBAL_PAYMENT' && typeof errorCode === 'string' && errorCode.length > 0) {
        if (errorCode.startsWith('2')) {
            message = i18n.t('GLOBAL_PAYMENT_ERROR_2XX');
        } else {
            message = i18n.t('GLOBAL_PAYMENT_ERROR_OTHER');
        }
    }

    return message;
};