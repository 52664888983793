import * as UI from '../constants/UI';

const initialState = {
    scrollY: null,
    updatedAt: null,
};

const UIReducer = (state = initialState, action) => {
    if (action.type === UI.SET_SCROLL_POSTION) {
        const { y } = action.payload;
        return {
            ...state,
            scrollY: y,
            updatedAt: Date.now(),
        };
    }
    return initialState;
}

export default UIReducer;