import { Button, CloseIcon, HStack, Input, Stack, Text } from 'native-base';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormFieldName } from '../store/constants/ShoppingCart';
import { updateFormAction } from '../store/actions/ShoppingCartActions';
import { copyAndAssignValues } from './Form/FormUtils';
import { useCartDetail } from '../queries/cart';

const PromotionCodeInput = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { form } = useSelector(state => state.shoppingCart);
    const [promotionCode, setPromotionCode] = useState(form[FormFieldName.promotionCode]);
    const [isApplied, setIsApplied] = useState(!!form[FormFieldName.promotionCode]);

    const { data: cart } = useCartDetail({
        withShippingCost: true,
    });
    const { promotionCodeActive } = cart;

    return (
        <Stack space={'3'}>
            <Text>{t('PROMOTION_CODE') + ':'}</Text>
            <HStack
                space={'5'}
                alignItems={'center'}
            >
                <Input 
                    flex={1} 
                    rounded={'sm'} 
                    placeholder={t('IF_APPLICABLE')} 
                    value={promotionCode}
                    onChange={(e) => {
                        setPromotionCode(e.nativeEvent.text)
                    }}
                    editable={!promotionCodeActive && !isApplied}
                />
                {(promotionCodeActive || isApplied) ? (
                    <Button
                        variant={'none'}
                        onPress={() => {
                            setIsApplied(false);
                            setPromotionCode('');
                            const newForm = copyAndAssignValues(form, {
                                name: FormFieldName.promotionCode,
                                value: '',
                            });
                            dispatch(updateFormAction(newForm));
                        }}
                    >
                        <CloseIcon/>
                    </Button>
                ) : (
                    <Button
                        height={'8'}
                        variant={'roundedFillOutline'}
                        onPress={() => {
                            if (promotionCode) {
                                setIsApplied(true);
                                const newForm = copyAndAssignValues(form, {
                                    name: FormFieldName.promotionCode,
                                    value: promotionCode,
                                });
                                dispatch(updateFormAction(newForm));
                            }
                        }}
                    >
                        {t('APPLY')}
                    </Button>
                )}
            </HStack>
        </Stack>
    );
}

export default PromotionCodeInput;