import {
    Center,
    HStack,
    Pressable,
    Text,
    View
} from 'native-base';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-native';
import useMeasure from 'react-use-measure';
import { useShopInfo } from '../queries/shop';
import AnimatedVStack from './AnimatedVStack';
import { CloseButton } from './CloseButton';

import { useCookies } from 'react-cookie';
import { useSpring } from 'react-spring';
import 'swiper/css';
import 'swiper/css/pagination';
import { useLocalStorage } from 'usehooks-ts';
import '../style/PopUpSwiper.css';
import ActionLink from './ActionLink';
import SelfSizeSwiperSlide from './SelfSizeSwiperSlide';
import _ from 'lodash';

const sid = 'adHistory';

const useAdFreqHist = () => {
    const [cookies, setCookie] = useCookies([sid]);
    const [localHistory, setLocalHistory] = useLocalStorage(sid, {});

    const sessionHistory = useMemo(() => cookies[sid] ? cookies[sid] : {}, [cookies]);
    const setSessionHistory = useCallback((obj) => {
        setCookie(sid, obj, { path: '/'});
    }, [setCookie]);

    // type of freq: forever_once, once, forever
    // forever_once, localStorage
    return useMemo(() => {
        return [
            {...localHistory, ...sessionHistory},
            (adId, frequency) => {
                const newLocalHistory = {...localHistory};
                const newSessionHistory = {...sessionHistory};
        
                const idList = Array.isArray(adId) ? adId: [adId];
                idList.forEach((id) => {
                    if (frequency === 'forever_once'){
                        newLocalHistory[id] = Date.now();
                    }
                    // once, session cookies
                    if (frequency === 'once'){
                        newSessionHistory[id] = Date.now();
                    }
                });
                
                setLocalHistory(newLocalHistory);
                setSessionHistory(newSessionHistory);
            },
        ];
    }, [localHistory, sessionHistory, setSessionHistory]);
};

const TopMessage = ({ topMsg }) => {
    const { adId, title, allowClose, action, actionTarget, frequency } = topMsg;
    const [show, setShow] = useState(true);

    const [ref, { height: containerHeight }] = useMeasure();

    const style = useSpring({
        height: containerHeight && show ? containerHeight : 0,
    });

    const showCloseButton = allowClose === true;

    const [, addAdFreqHistory] = useAdFreqHist();

    useEffect(() => {
        addAdFreqHistory(adId, frequency);
    }, []);


    return (
        <AnimatedVStack bg={'lightText'} style={style}>
            <View ref={ref}>
                <HStack
                    margin={'2.5'}
                    paddingLeft={'6'}
                    paddingRight={showCloseButton ? 0 : '6'}>
                    
                    {show && (
                        <Center flex={1}>
                            <ActionLink action={action} actionTarget={actionTarget}>
                                <Text color={'white'} textAlign={'center'}>
                                    {title}
                                </Text>
                            </ActionLink>
                        </Center>
                    )}
                    
                    {show && showCloseButton && (
                        <CloseButton
                            onPress={() => {
                                setShow(false);
                            }}
                            color={'white'}
                        />
                    )}
                </HStack>
            </View>
        </AnimatedVStack>
    );
};

export const Popup = ({ popupAd = [] }) => {
    const [open, setOpen] = useState(true);
    const [, saveAdFreqHist] = useAdFreqHist();

    return (
        <Modal visible={open} transparent={true}>
            <>
                <Pressable
                    position={'absolute'}
                    size={'full'}
                    background={'black'}
                    opacity={0.7}
                    onPress={() => {
                        setOpen(false);
                    }}
                />
                <Center size={'full'} marginTop={'8'}>
                    <View width={'full'} height={'80%'}>
                        <SelfSizeSwiperSlide items={popupAd} onShow={(items) => {
                            const loadedAdId = _.map(items, 'adId');
                            const frequency = 'once';
                            saveAdFreqHist(loadedAdId, frequency);
                        }}/>
                    </View>
                </Center>
                <CloseButton
                    isWhiteIcon={true}
                    position={'absolute'}
                    top={30}
                    right={30}
                    size={'12'}
                    onPress={() => {
                        setOpen(false);
                    }}
                />
            </>
        </Modal>
    );
};

export const AdComponent = () => {
    const { data: shopInfo } = useShopInfo();
    const { ads = {} } = shopInfo;
    const { popupAd = [], topMsg = {} } = ads;

    const [_adFreqHist] = useAdFreqHist();
    const [adFreqHist] = useState(_adFreqHist); // use snapshot of history list

    const freqHistAdIdList = Object.keys(adFreqHist);

    const filteredPopupAd = popupAd.filter(
        ad =>!freqHistAdIdList.includes(`${ad.adId}`));

    const topMsgList = Array.isArray(topMsg) ? topMsg : [topMsg];

    const filteredTopMsgList = topMsgList
        .filter(ad => ad)
        .filter(ad =>!freqHistAdIdList.includes(`${ad.adId}`));

    return (
        <>
            {filteredTopMsgList.length > 0 &&
                filteredTopMsgList.map((topMsg, index) => (
                    <TopMessage key={index} topMsg={topMsg} />
                ))}
            {filteredPopupAd.length > 0 && <Popup popupAd={filteredPopupAd} />}
        </>
    );
};
