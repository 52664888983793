import * as ShoppingCart from '../constants/ShoppingCart';
import _ from 'lodash';

const initialState = {
    loaded: false,
    skus: [],
    products: [],
    quantities: [],
    subTotal: [],
    total: 0,
    shippingCost: 0,
    discount: 0,
    coupons: [],
    form: {},
    deliveryOption: {},
    deliveryOptionOptionIds: {},
    deliveryOptionTexts: {},
    paymentOption: {},
    pendingOrderNo: null,
    selectedStock: [],
};

const ShoppingCartReducer = (state = initialState, action) => {
    if (action.type === ShoppingCart.LOAD) {
        const {
            shoppingCart = {}
        } = action.payload;

        return {
            ...initialState,
            ...shoppingCart,
            loaded: true,
        };
    }

    if (action.type === ShoppingCart.ADD_PRODUCT) {
        const { product, sku, quantity } = action.payload;
        if (quantity > 0) {
            const { price } = sku;
            const found = state.skus.findIndex(i => i.id === sku.id);

            if (found >= 0) {
                let quantities = [...state.quantities];
                const newQuantities = quantities[found] + quantity;
                quantities[found] = newQuantities;

                let subTotal = [...state.subTotal];
                const newSubTotal = newQuantities * price;
                subTotal[found] = newSubTotal;

                return {
                    ...state,
                    quantities,
                    subTotal,
                    total: subTotal.reduce((p, c) => p + c, 0),
                };
            } else {
                const subTotal = [...state.subTotal, price * quantity];

                return {
                    ...state,
                    skus: [...state.skus, sku],
                    products: [...state.products, product],
                    quantities: [...state.quantities, quantity],
                    subTotal,
                    total: subTotal.reduce((p, c) => p + c, 0),
                };
            }
        }
    }

    if (action.type === ShoppingCart.DEDUCE_PRODUCT) {
        const { product } = action.payload;
        const found = state.products.findIndex(i => i.id === product.id);

        if (found) {
            let quantities = [...state.quantities];

            if (quantities[found] - 1 === 0) {
                let products = [...state.products];
                products.splice(found, 1);
                quantities.splice(found, 1);

                return {
                    ...state,
                    products,
                    quantities,
                };
            } else {
                quantities[found] = quantities[found] - 1;
                return {
                    ...state,
                    quantities,
                };
            }
        }
    }

    if (action.type === ShoppingCart.REMOVE_PRODUCT) {
        const { sku } = action.payload;
        const found = state.skus.findIndex(i => i.id === sku.id);

        if (found > -1) {
            let skus = [...state.skus];
            let products = [...state.products];
            let quantities = [...state.quantities];
            let subTotal = [...state.subTotal];

            skus.splice(found, 1);
            products.splice(found, 1);
            quantities.splice(found, 1);
            subTotal.splice(found, 1);

            return {
                ...state,
                skus,
                products,
                quantities,
                subTotal,
                total: subTotal.reduce((p, c) => p + c, 0),
            };
        }
    }

    if (action.type === ShoppingCart.USE_COUPON) {
        const { coupon } = action.payload;
        const found = state.coupons.findIndex(i => i.id === coupon.id);
        if (found === -1) {
            let coupons = [...state.coupons];
            coupons.push(coupon);

            const discount = coupons.reduce((p, c) => p + c.value, 0);

            return {
                ...state,
                coupons,
                discount,
            };
        }
    }

    if (action.type === ShoppingCart.UNUSE_COUPON) {
        const { coupon } = action.payload;
        const found = state.coupons.findIndex(i => i.id === coupon.id);
        if (found > -1) {
            let coupons = [...state.coupons];
            coupons.splice(found, 1);

            const discount = coupons.reduce((p, c) => p + c.value, 0);

            return {
                ...state,
                coupons,
                discount,
            };
        }
    }

    if (action.type === ShoppingCart.UPDATE_FORM) {
        const { form = {} } = action.payload;
        if (Object.keys(form).length === 0) {
            return {
                ...state,
                form,
                deliveryOption: {},
                paymentOption: {},
            };
        }
        return {
            ...state,
            form,
        };
    }

    if (action.type === ShoppingCart.SET_DELIVERY_OPTION) {
        const { deliveryOption } = action.payload;
        return {
            ...state,
            deliveryOption,
        };
    }

    if (action.type === ShoppingCart.SET_PAYMENT_OPTION) {
        const { paymentOption } = action.payload;

        return {
            ...state,
            paymentOption,
        };
    }

    if (action.type === ShoppingCart.DROP_SHOPPING_CART) {
        return {
            ...initialState,
            loaded: true,
        };
    }

    if (action.type === ShoppingCart.SET_DELIVERY_OPTION_SUBOPTION) {
        const { shippingMethodId, optionId } = action.payload;

        return {
            ...state,
            deliveryOptionOptionIds: {
                ...state.deliveryOptionOptionIds,
                [shippingMethodId]: optionId,
            },
        };
    }

    if (action.type === ShoppingCart.SET_DELIVERY_OPTION_TEXT) {
        const { shippingMethodId, text } = action.payload;

        return {
            ...state,
            deliveryOptionTexts: {
                ...state.deliveryOptionTexts,
                [shippingMethodId]: text,
            },
        };
    }

    if (action.type === ShoppingCart.RESET_FORM) {
        return {
            ...state,
            form: {},
        };
    }

    if (action.type === ShoppingCart.SAVE_PENDING_ORDER_NO) {
        const { pendingOrderNo } = action.payload;

        return {
            ...state,
            pendingOrderNo,
        };
    }

    if (action.type === ShoppingCart.DELETE_PENDING_ORDER_NO) {
        return {
            ...state,
            pendingOrderNo: null,
        };
    }

    if (action.type === ShoppingCart.SELECT_STOCK) {
        const { selectedStock: oldSelectedStock } = state;
        const { stockIdList = [] } = action.payload;
        const selectedStock = _.chain(oldSelectedStock).concat(stockIdList).uniq().value();

        return {
            ...state,
            selectedStock,
        };
    }

    if (action.type === ShoppingCart.UNSELECT_STOCK) {
        const { selectedStock: oldSelectedStock } = state;
        const { stockIdList = [] } = action.payload;
        const selectedStock = _.difference(oldSelectedStock, stockIdList);

        return {
            ...state,
            selectedStock,
        };
    }

    if (action.type === ShoppingCart.RESET_SELECTED_STOCK) {
        return {
            ...state,
            selectedStock: [],
        };
    }

    return state;
};

export default ShoppingCartReducer;