import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { allStyles, fieldHeight, textFontSize } from './FormStyles';
import TitleLabel from './TitleLabel';

const styles = StyleSheet.create(allStyles);

export default function FieldBubble(props) {
    const items = props.getItemsByName(props.name);
    let selectedValues = [];
    if (props.value) {
        selectedValues = Array.isArray(props.value)
            ? props.value
            : [props.value];
    }

    return (
        <View style={[styles.fieldContainer, props.containerStyle]}>
            <TitleLabel {...props} />
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {items.map((item, i) => (
                    <TouchableOpacity
                        key={i}
                        style={{
                            height: fieldHeight * 0.8,
                            backgroundColor: selectedValues.includes(item.value)
                                ? '#212121'
                                : '#FFFFFF',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderColor: '#ADADAD',
                            borderWidth: StyleSheet.hairlineWidth,
                            borderRadius: fieldHeight * 0.5,
                            paddingHorizontal: 18,
                            marginRight: 6,
                            marginBottom: 6,
                        }}
                        onPress={() => {
                            let newValues = [...selectedValues];

                            if (props.multiple) {
                                if (selectedValues.includes(item.value)) {
                                    newValues = selectedValues.filter(
                                        v => v != item.value,
                                    );
                                } else {
                                    newValues.push(item.value);
                                }
                            } else {
                                newValues = item.value;
                            }

                            props.onChange({
                                name: props.name,
                                value: newValues,
                            });
                        }}>
                        <Text
                            style={{
                                color: selectedValues.includes(item.value)
                                    ? '#fff'
                                    : '#212121',
                                fontSize: textFontSize,
                            }}>
                            {item.label}
                        </Text>
                    </TouchableOpacity>
                ))}
            </View>
        </View>
    );
}
