import { HStack, Text, View, VStack } from 'native-base';
import React from 'react';

const CenterLineTitle = ({ title, subTitle, color }) => {
    return (
        <VStack>
            <HStack alignItems={'center'} space={'1'}>
                <View
                    height={'2px'}
                    width={'5'}
                    backgroundColor={'lightText'}
                />
                <Text bold>{title}</Text>
                <View
                    height={'2px'}
                    flexGrow={1}
                    backgroundColor={'lightText'}
                />
            </HStack>
            {subTitle && <Text marginLeft={'6'}>{subTitle}</Text>}
        </VStack>
    );
};

export default CenterLineTitle;
