import React from 'react';
import { StyleSheet, TextInput, View } from 'react-native';
import { allStyles, placeholderTextColor } from './FormStyles';
import { translate } from './FormUtils';
import TitleLabel from './TitleLabel';

const styles = StyleSheet.create(allStyles);

export default function FieldCheckbox(props) {
    return (
        <View style={[styles.fieldContainer, props.containerStyle]}>
            <TitleLabel
                {...props}
                sideCheckbox={{
                    name: props.name,
                }}
            />
        </View>
    );
}
