import { HStack, Icon, IconButton, Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import Feather from 'react-native-vector-icons/dist/Feather';

const QuantityButton = ({
    quantity,
    minValue = 0,
    maxValue = -1,
    disabled = false,
    onChange = () => {},
}) => {
    return (
        <HStack
            alignItems={'center'}
            space={'1'}
            paddingX={'1'}
            borderWidth={'1'}
            borderRadius={'full'}
            borderColor={'lightText'}>
            <IconButton
                disabled={disabled}
                size={'6'}
                icon={<Icon as={Feather} name={'minus'} color={'lightText'} />}
                onPress={() => {
                    if (quantity > minValue) {
                        const newQuantity = quantity - 1;
                        // setQuantity(newQuantity);
                        onChange(newQuantity);
                    }
                }}
            />
            <Text bold>{quantity}</Text>
            <IconButton
                disabled={disabled}
                size={'6'}
                icon={<Icon as={Feather} name={'plus'} color={'lightText'} />}
                onPress={() => {
                    if (maxValue < 0 || quantity < maxValue) {
                        const newQuantity = quantity + 1;
                        // setQuantity(newQuantity)
                        onChange(newQuantity);
                    }
                }}
            />
        </HStack>
    );
};

export default QuantityButton;
