import axios from 'axios';

// let customHeader = {};

axios.defaults.withCredentials = true;

// axios.interceptors.request.use((config) => {
//     if (customHeader['x-my-cart']) {
//         config.headers['x-my-cart'] = customHeader['x-my-cart'];
//     }
//     return config;
// });

// axios.interceptors.response.use((config) => {
//     if (config.headers['x-my-cart']) {
//         customHeader = {
//             'x-my-cart': config.headers['x-my-cart'],
//         }
//     }
//     return config;
// });

export const requests = {
    get: ({ url, params }) =>
        axios.get(url, {
            params,
        }),
    post: ({ url, data, headers }) =>
        axios.post(url, data, {
            headers,
        }),
    put: ({ url, data }) => axios.put(url, data, {}),
    delete: ({ url, params }) =>
        axios.delete(url, {
            params,
        }),
    patch: ({ url, data }) => axios.patch(url, data, {}),
};
