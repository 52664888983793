import { Icon, IconButton, Menu, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Octicons from 'react-native-vector-icons/dist/Octicons';
import { useNavigate, useParams } from 'react-router-dom';
import { useShopOGInfo } from '../providers/ShopOGInfoContext';
import { useGeneratePathFunc } from '../utils/routeUtils';
import useCurrentPage from '../utils/useCurrentPage';

const LanguageMenuButton = () => {
    const { data } = useShopOGInfo();
    const { supportLocales = [] } = data;

    const generatePath = useGeneratePathFunc();

    const { t } = useTranslation();

    const params = useParams();

    const currentRoute = useCurrentPage(true);

    const navigate = useNavigate();

    return (
        <Menu
            trigger={triggerProps => (
                <IconButton
                    size={'6'}
                    icon={
                        <Icon
                            as={Octicons}
                            name={'globe'}
                            size={21}
                            color={'lightText'}
                        />
                    }
                    {...triggerProps}
                />
            )}>
            {supportLocales.map(locale => {
                const url = generatePath(currentRoute.path, {
                    ...params,
                    lang: locale,
                });

                return (
                    <Menu.Item
                        key={locale}
                        onPress={() => {
                            navigate(url);
                        }}>
                        {/* <Link to={url}>
                            <Text>{t(locale)}</Text>
                        </Link> */}
                        <Text>{t(locale)}</Text>
                    </Menu.Item>
                );
            })}
        </Menu>
    );
};

export default LanguageMenuButton;
