import { combineReducers } from 'redux';
import MemberReducer from './MemberReducer';
import OrderReducer from './OrderReducer';
import ShoppingCartReducer from './ShoppingCartReducer';
import ShopReducer from './ShopReducer';
import UIReducer from './UIReducer';
import ProductReducer from './ProductReducer';

const reducers = combineReducers({
    shop: ShopReducer,
    shoppingCart: ShoppingCartReducer,
    member: MemberReducer,
    order: OrderReducer,
    ui: UIReducer,
    product: ProductReducer,
});

export default reducers;
