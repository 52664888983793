import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queries, useFetcher } from '.';
import { deleteFavorite, postFavorite } from '../api/shop';
import { SortingMethod } from '../components/SortingMethodSelector';
import { useAppContext } from '../providers/AppProvider';
import { SpecialCategories } from '../constrants/categories';

export const useShopCategoryList = () => useFetcher(queries.categories);

export const useShopProductList = (categoryId, pagingParams) => {
    let newPagingParams = { ...pagingParams };
    if (newPagingParams.sortingMode === SortingMethod.recommended) {
        delete newPagingParams.sortingMode;
    }

    return useFetcher(queries.products, {
        categoryId,
        paging: newPagingParams,
    }, {
        keepPreviousData: true,
    });
};

export const useShopProductSearch = (searchKeyword, pagingParams) => {
    let newPagingParams = { ...pagingParams };
    if (newPagingParams.sortingMode === SortingMethod.recommended) {
        delete newPagingParams.sortingMode;
    }

    return useFetcher(queries.productSearch, {
        keyword: searchKeyword,
        paging: newPagingParams,
    }, {
        enabled: !!searchKeyword,
    });
};

export const useShopProductDetail = (categoryId, productId) => {
    return useFetcher(queries.product, {
        categoryId, productId,
    }, {
        keepPreviousData: true,
    });
};

export const useShopProducStock = (categoryId, productId, colorId, answers) => {
    const questionAnswer = answers ? answers.join(',') : [];

    return useFetcher(queries.stock, {
        categoryId,
        productId,
        colorId,
        questionAnswer,
    }, {
        refetchOnMount: 'always',
        keepPreviousData: true,
    });
};

const updateIsFavorite = (nextValue = false) => {
    return (res) => {
        if (res.data) {
            return {
                ...res,
                data: {
                    ...res.data,
                    isFavorite: nextValue,
                },
            };
        }

        return res;
    };
};

export const useSetFavorite = () => {
    const { shopId, lang } = useAppContext();
    const queryClient = useQueryClient();

    return useMutation(postFavorite, {
        onSuccess: (data, variables) => {
            const { categoryId, productId } = variables;
            queryClient.setQueryData(queries.product({shopId, lang, categoryId, productId}).queryKey, updateIsFavorite(true));
            queryClient.invalidateQueries(queries.productsTotal({shopId, lang, categoryId:'favorite'}));
        },
    });
};

export const useDeleteFavorite = () => {
    const { shopId, lang } = useAppContext();
    const queryClient = useQueryClient();

    return useMutation(deleteFavorite, {
        onSuccess: (data, variables) => {
            const { categoryId, productId } = variables;
            queryClient.setQueryData(queries.product({shopId, lang, categoryId, productId}).queryKey, updateIsFavorite(false));
            queryClient.invalidateQueries(queries.productsTotal({shopId, lang, categoryId:SpecialCategories.favorite.id}));
        },
    });
};
