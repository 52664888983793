import { Helmet } from 'react-helmet-async';
import { useShopOGInfo } from '../providers/ShopOGInfoContext';

export const RootHead = () => {
    const { data } = useShopOGInfo();
    const { ogTitle, faviconImage, ogDescription, ogKeyword, iosLink, googleLink } = data;
    let appstoreId = null;
    if (iosLink != null && iosLink.length > 0){
        let appstoreIdStart = iosLink.indexOf('id');
        if (appstoreIdStart > 0){
            appstoreIdStart += 2;
            
            let appstoreIdEnd = iosLink.indexOf('?');
            if (appstoreIdEnd < 0){
                appstoreIdEnd = iosLink.length;
            }
            appstoreId = 'app-id='+iosLink.substring(appstoreIdStart, appstoreIdEnd);
        }
    }
    let playStorePackageName = null;
    if (googleLink != null && googleLink.length > 0){
        let playStorePackageNameStart = googleLink.indexOf('id=');
        if (playStorePackageNameStart > 0){
            playStorePackageNameStart += 3;
            
            let playStorePackageNameEnd = iosLink.indexOf('&', playStorePackageNameStart);
            if (playStorePackageNameEnd < 0){
                playStorePackageNameEnd = googleLink.length;
            }
            playStorePackageName = 'app-id='+googleLink.substring(playStorePackageNameStart, playStorePackageNameEnd);
        }
    }

    return (
        <Helmet>
            <title>{ogTitle}</title>
            <link
                rel={'shortcut icon'}
                type={'image/png'}
                href={faviconImage}
                sizes={'16x16'}
            />
            <meta property="og:title" content={ogTitle} />
            <meta property="og:description" content={ogDescription} />
            <meta property="og:keyword" content={ogKeyword} />
            <meta property="og:image" content={faviconImage} />
            {(appstoreId && (<meta name="apple-itunes-app" content={appstoreId}/>))}
            {(playStorePackageName && (<meta name="google-play-app" content={playStorePackageName}/>))}
        </Helmet>
    );
};
