export const actions = {
    category: {
        scheme: 'category',
    },
    product: {
        scheme: 'product',
    },
    pageContent: {
        scheme: 'pageContent',
    },
    page: {
        scheme: 'page',
    },
    search: {
        scheme: 'search',
    },
    promotion: {
        scheme: 'promotion',
    },
};

export const extractFromAction = actionTarget => {
    const groups = actionTarget?.match(/([a-z]*):\/\/(.*)/) ?? [];
    const scheme = groups[1];
    const id = groups[2];

    return {
        scheme,
        id,
    };
};
