import { useDispatch, useSelector } from 'react-redux'
import { useAppContext } from './providers/AppProvider';
import { useSessionStorage } from 'usehooks-ts';
import { useEffect } from 'react';
import { loadShoppingCartAction } from './store/actions/ShoppingCartActions';

const ShoppingCartSessionLoader = ({
  children,
}) => {
  const { shopId } = useAppContext();
  const shoppingCart = useSelector(state => state.shoppingCart);
  const [sessionShoppingCart, setSessionShoppingCart] = useSessionStorage(`${shopId}.shoppingCart`);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadShoppingCartAction(sessionShoppingCart));
  }, []);

  useEffect(() => {
    if (shoppingCart.loaded) {
      setSessionShoppingCart(shoppingCart);
    }
  }, [shoppingCart.loaded, shoppingCart]);

  if (shoppingCart.loaded) {
    return children;
  }

  return null;
};

export default ShoppingCartSessionLoader;