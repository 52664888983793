import React, { createContext, useContext, useMemo } from 'react';
import { useShopOGInfo as useShopOGInfoQuery } from '../queries/shop';
import { useAppContext } from './AppProvider';

const ShopOGInfoContext = createContext({});

const ShopOGInfoProvider = ({ children }) => {
    const { shopShortName, shopDomain } = useAppContext();
    const { data } = useShopOGInfoQuery(shopShortName, shopDomain);

    const value = useMemo(() => ({
        data
    }), [data]);

    return (
        <ShopOGInfoContext.Provider
            value={value}>
            {children}
        </ShopOGInfoContext.Provider>
    );
};

const useShopOGInfo = () => {
    const context = useContext(ShopOGInfoContext);
    const { data } = context;

    const value = useMemo(() => ({
        data
    }), [data]);

    return value;
};

export { ShopOGInfoProvider, useShopOGInfo };
