import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { allStyles } from './FormStyles';
import { translate, useTranslateFunc } from './FormUtils';

const styles = StyleSheet.create(allStyles);

export default function TitleLabel(props) {
    let title = '';

    title = translate(props.title);

    const translateFunc = useTranslateFunc();

    if (title.length === 0) {
        return <View />;
    }

    var checked = false;
    if (props.sideCheckbox) {
        checked = props.getValueByName(props.sideCheckbox.name) ?? false;
    }

    return (
        <View style={{ flexDirection: 'row' }}>
            {props.icon && (
                <Image
                    style={{
                        width: 16,
                        height: 16,
                        marginTop: 5,
                        marginRight: 5,
                    }}
                    resizeMode={'contain'}
                    source={props.icon}
                    alt={''}
                />
            )}
            <Text style={styles.title}>
                {title}
                {props.required && !props.hideStar && (
                    <Text style={styles.red}>{'*'}</Text>
                )}
                <Text>{': '}</Text>
                {props.error && !props.hideError && (
                    <Text style={styles.red}>{props.error}</Text>
                )}
                {props.tip && <Text style={styles.tip}>{props.tip}</Text>}
            </Text>
            {props.sideCheckbox && (
                <TouchableOpacity
                    style={styles.checkboxContainer}
                    onPress={() => {
                        props.onChange({
                            name: props.sideCheckbox.name,
                            value: !checked,
                        });
                    }}>
                    <View
                        style={[styles.checkbox, checked && styles.checked]}
                    />
                    {props.sideCheckbox.title && (
                        <Text style={styles.text}>
                            {translateFunc(props.sideCheckbox.title)}
                        </Text>
                    )}
                </TouchableOpacity>
            )}
        </View>
    );
}
